import axios from "axios";
import { userSignupURL } from "./endpoints";

export async function UserSignupAPIs(
  email: string,
  // name: string,
  // email: string,
  // password: string,
  // phone: string,
  // collegeName: string
): Promise<any> {
  const payload = {
    email,
    // name,
    // email,
    // password,
    // phone,
    // collegeName,
  };
  const headers = {
    'Content-Type': 'application/json',
  };
  try {
    const response = await axios.post(userSignupURL, payload,
      {
        headers
      });
    console.error("response signup:", response);
    return response.data;
  } catch (error) {
    console.error("Error during user signup:", error);
    throw error;
  }
}
