import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { HeaderComponent } from '../component/form/Header';

const DemoQuizList: React.FC = () => {
    const [questions, setQuestions] = useState<any[]>([]);
    const [error, setError] = useState<string | null>(null);
    const opening_users_id = localStorage.getItem('opening_users_id');
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };

                const response = await axios.post(
                    'https://avaapi.flair-solution.com/api/questions/list',
                    {
                        opening_users_id
                    },
                    {
                        headers: headers
                    }
                );

                if (response.data.success) {
                    const questionsData = response.data.data;
                    setQuestions(questionsData);
                } else {
                    setError(response.data.message || 'Unknown error');
                }
            } catch (error: any) {
                if (error.response) {
                    setError(`Error: ${error.response.status} - ${error.response.data}`);
                } else if (error.request) {
                    setError('No response received from the server');
                } else {
                    setError('Error while setting up the request');
                }
            }
        };

        fetchData();
    }, [opening_users_id, token]);


    return (
        <div>
            <style>
                {`
                    #header {
                        background: #2a2a2a;
                    }
                `}
            </style>
            <HeaderComponent />
            {error && <div>{error}</div>}
            {questions.length > 0 ? (
                <ul className="questionslist">
                    {questions.map((question: any) => (
                        <li key={question.id}>{question.title}</li>
                    ))}
                </ul>
            ) : (
                <div>No questions available.</div>
            )}
        </div>
    );
};

export default DemoQuizList;
