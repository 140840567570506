import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faVideoSlash, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons';
import '../assets/css/Dashboard.css';
import { SideBar } from './SideBar';

export const ConfigError = () => {

    return (<div className='sidebar_container'>
        <SideBar />
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-8 m-auto">
                    <div className="access_container">
                        <p><strong>Hi, Test User</strong></p>
                        <p>AVA needs access to your camera and microphone in order to capture your amazing talks! Please check your browser's Privacy Settings to give Yoodli audio and video permission, and refresh the page when you’re done.</p>
                        <div className="access_icon my-4">
                            <div className="sub_access_icon">
                                <FontAwesomeIcon icon={faVideoSlash} />
                            </div>
                            <div className="sub_access_icon">
                                <FontAwesomeIcon icon={faMicrophoneSlash} />
                            </div>
                        </div>

                        <div className="access_btn my-3">
                            {/* <Link to="/testQuiz"> */}
                            {/* <button className="btn" type="button">
                                <FontAwesomeIcon icon={faVideo} />  Give access to camera and mic
                            </button> */}
                            {/* </Link> */}
                        </div>
                        <p>Having trouble? please <span className="refference_video">
                            <a href="#"> watch this video</a>
                        </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

