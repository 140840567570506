import { Route, Routes, Router } from "react-router-dom";
import React from "react";
import { Home } from "./pages/Home";
import { EmailForm } from "./pages/EmailForm";
import { EmailSignUp } from "./pages/EmailSignUp";
import { Dashboard } from "./pages/Dashboard";
import { RecordPractice } from "./pages/RecordPractice";
import { CheckSystemConfig } from "./pages/CheckSystemConfig";
import { LiveQuizComponent } from "./pages/LiveQuizComponent";
import { TestQuiz } from "./pages/TestQuiz";
import { HomePage } from "./pages/HomePage";
import { LoginPage } from "./pages/LoginPage";
import { SignupPage } from "./pages/SignupPage";
import { LandingPage } from "./pages/LandingPage";
import { RealQuiz } from "./pages/RealQuiz";
import { Camera } from "./pages/Camera";
import { Tickets } from "./pages/Tickets";
import { SideBar } from "./pages/SideBar";
import { DashboardPage } from "./pages/DashboardPage";
import { ConfigError } from "./pages/ConfigError";
import { ConfigCheck } from "./pages/ConfigCheck";
import { PracticeTest } from "./pages/PracticeTest";
import { LiveQuiz } from "./pages/LiveQuiz";
import { OpeningUI } from "./pages/OpeningUI";
import UserFromEmail from "./pages/UserFromEmail";
import DemoQuizList from "./pages/DemoQuizList";
import { PracticeQuizComponent } from "./pages/PracticeQuizComponent";
import { Allopenings } from "./pages/Allopenings";
import QuizDetailPage from "./pages/QuizDetailPage";
import { LiveTestPage } from "./pages/LiveTestPage";
import { TestDetailForCandidate } from "./pages/TestDetailForCandidate";
import { LiveTestDuplicatePage } from "./pages/LiveTestDuplicatePage";
import { DuplicatePracticeQuiz } from "./pages/DuplicatePracticeQuiz";
import VideoInterviewTemplate from "./pages/VideoInterviewTemplate";
import VideoPlayer from "./pages/videoplayer"

export const PageRoutes = () => {

  return (
    <Routes>
      <Route path="/start-page" element={<Home />} />
      <Route path="/" element={<LandingPage />} />
      <Route path="/sign-in" element={<EmailForm />} />
      <Route path="/signup" element={<EmailSignUp />} />
      <Route path="/dashboardPage" element={<Dashboard />} />
      <Route path="/tickets" element={<Tickets />} />
      <Route path="/recordpractice" element={<RecordPractice />} />
      <Route path="/system-config" element={<CheckSystemConfig />} />
      {/* <Route path="/test-practice" element={<QuizComponent />} /> */}
      <Route path="/testQuiz" element={<TestQuiz />} />
      <Route path="/live-quiz" element={<RealQuiz />} />
      <Route path="/landing-page" element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/sign-up" element={<SignupPage />} />
      <Route path="/camera" element={<Camera />} />
      <Route path="/sideBar" element={<SideBar />} />
      <Route path="/dashboard" element={<DashboardPage />} />
      <Route path="/configError" element={<ConfigError />} />
      <Route path="/configCheck" element={<ConfigCheck />} />
      <Route path="/practiceTest" element={<PracticeQuizComponent />} />
      {/* <Route path="/liveQuiz" element={<LiveQuiz />} /> */}
      <Route path="/liveQuiz" element={<LiveQuizComponent />} />
      <Route path="/opening-job" element={<OpeningUI />} />
      <Route path="/UserFromEmail" element={<UserFromEmail />} />
      <Route path="/DemoQuizList" element={<DemoQuizList />} />
      <Route path="/allopenings" element={<Allopenings />} />
      {/* <Route path="/quizdetailpage" element={<QuizDetailPage />} /> */}
      <Route path="/quizdetailpage/:openingId" element={<QuizDetailPage />} />
      <Route path="/LiveTestPage" element={<LiveTestPage />} />
      <Route path="/testdetails" element={<TestDetailForCandidate />} />
      <Route path="/LiveTestDuplicate" element={<LiveTestDuplicatePage />} />
      <Route path="/demopracticetest" element={<DuplicatePracticeQuiz />} />
      <Route path="/VideoPlayer" element={<VideoPlayer />} />

    </Routes>
  );
};

