import React, { useState, useEffect, useRef, useCallback } from 'react';
import AWS from 'aws-sdk';
import axios from 'axios';
import { useReactMediaRecorder } from 'react-media-recorder';
import { Card, Button, Spinner } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import videoImg from '../assets/images/defult-Video.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faInfo } from '@fortawesome/free-solid-svg-icons';
import { SideBar } from './SideBar';
import Webcam from "react-webcam";

import { config } from './config';

interface QuizQuestion {
    question: string;
    videoURL: string;
    waitTime: number;
    answerTime: number;
    questionId: number;
}

export const LiveTestPage: React.FC = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const openingUserId = searchParams.get("opening_users_id");



    const Ref = useRef(null);
    const isStoppingRef = useRef(false);
    // The state for our timer
    const [timer, setTimer] = useState("00:00:00");

    const [waitTimerSeconds, setWaitTimerSeconds] = useState(0);
    const [answerTimerSeconds, setAnswerTimerSeconds] = useState(0);



    const [showScore, setShowSuccessScore] = useState(false);
    const [questions, setQuestions] = useState<QuizQuestion[]>([]);
    const webcamRef = useRef<any>(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [questionSubmitted, setQuestionSubmittedFlag] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state
    const [blobDataUrl, setBlobDataUrl] = useState(""); // Loading state
    const [response, setResponse] = useState(null);

    const navigate = useNavigate();

    let blobUrlData: string | undefined = "";

    let stopCounter = 0;

    const handleStop = useCallback(async (blobUrl: string | undefined) => {
        if (isStoppingRef.current) {
            console.log("status:-", status);
            console.log("blobUrl:-", blobUrl); // This should log the blob URL
            blobUrlData = blobUrl;
            // You can handle your submit logic here
            isStoppingRef.current = false;
        }

    }, []);

    const { error: mediaError, status, startRecording, stopRecording, mediaBlobUrl } = useReactMediaRecorder({
        audio: true,
        video: true,
        // blobPropertyBag: { type: 'video/webm' },
        onStop: async (blobUrl) => {
            console.log("stopCounter:----", stopCounter);
            setBlobDataUrl(blobUrl);
            let uploadedResult = await uploadToS3(blobUrl);
            // setLoading(true);
            // setTimeout(async () => {
            //   if (stopCounter === 0 && currentQuestionIndex === 0) {
            //     let uploadedResult = await uploadToS3(blobUrl);
            //     if (uploadedResult === true) {
            //       stopCounter++;
            //     }
            //   }
            //   else if (currentQuestionIndex > 0) {
            //     await uploadToS3(blobUrl);
            //   }
            //   console.log('Recording stopped and stopCounter:----', stopCounter);
            // }, 2000);
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const opening_users_id = localStorage.getItem('opening_users_id');
                const token = localStorage.getItem('token');
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                };
                const response = await axios.post(
                    'https://avaapi.flair-solution.com/api/questions/list',
                    { opening_users_id },
                    { headers }
                );
                if (response.data.success) {
                    const apiQuestions: QuizQuestion[] = response.data.data.map((question: any) => ({
                        question: question.title,
                        videoURL: question.video_link,
                        waitTime: question.wait_time_in_sec,
                        answerTime: question.answer_time_in_sec,
                        questionId: question.question_id
                    }));
                    setQuestions(apiQuestions);
                    // Initialize timers
                    // if (apiQuestions.length > 0) {
                    //   setWaitTimerSeconds(apiQuestions[0].waitTime);
                    //   setAnswerTimerSeconds(apiQuestions[0].answerTime);
                    // }
                } else {
                    console.error(response.data.message || 'Unknown error');
                }
            } catch (error) {
                console.error('Error while fetching data from API:', error);
            }
        };

        fetchData();
    }, []);

    const handleNextClick = async () => {
        // setCurrentQuestionIndex((prevIndex: number) => Math.min(prevIndex + 1, questions.length - 1));
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    const formatQuestionNumber = (index: number) => `Q${index + 1}`;

    // console.log("questions[currentQuestionIndex]?.videoURL:--", questions[currentQuestionIndex]?.videoURL);
    // console.log("Camera status:-", status);
    // console.log("mediaBlobUrl:-", mediaBlobUrl);

    const startRecordingAnswer = async () => {
        setWaitTimerSeconds(0);
        startRecording();
        if (questions && questions[currentQuestionIndex] && questions[currentQuestionIndex].answerTime) {
            setAnswerTimerSeconds(questions[currentQuestionIndex].answerTime)
        }
    }

    const stopRecordingAnsAndSubmit = async () => {
        isStoppingRef.current = true;
        setAnswerTimerSeconds(0);

        stopRecording();
        // console.log("mediaBlobUrl:--------", mediaBlobUrl);

        // setTimeout(async () => {
        //   console.log("stopRecordingAnsAndSubmit status:--------", status);
        // //   if (status === 'stopped') {
        // //     // const handleStop = useCallback(async (blobUrl: string | undefined) => {
        // //     //   console.log(blobUrl); // This should log the blob URL
        // //     //   // You can handle your submit logic here
        // //     // }, []);
        // //     await uploadToS3(blobUrlData);
        // //     // stopRecording();
        // //     // setQuestionSubmittedFlag(false);
        // //     // setLoading(false); // Hide loader after upload
        // //     // handleNextClick();
        // //   }
        // // }, 2000);
        console.log("stopRecordingAnsAndSubmit Camera Status:-", status);
    }

    const uploadToS3 = async (blobUrl: string | undefined) => {
        setLoading(true);
        const region = 'ap-south-1';

        if (!blobUrl) {
            console.error('Media blob URL is not available');
            setLoading(false); // Add this line to handle loading state
            return;
        }
        let resultBlob = await fetchData(blobUrl);

        const tenantId = localStorage.getItem('tenant_id');
        const applicantId = localStorage.getItem('opening_users_id');
        let payload: any = {
            tenant_id: tenantId,
            application_id: applicantId
        };
        let fileName = `${tenantId}_${applicantId}_${questions[currentQuestionIndex]?.questionId}_${new Date().getTime()}`;

        console.log("resultBlob:-", resultBlob);

        const blob: any = new Blob([resultBlob || ""], { type: 'video/webm' }); // Example Blob
        const file = new File([blob], `${fileName}.webm`, { type: 'video/webm' });

        AWS.config.update({
            accessKeyId: config.accessKeyId,//AWS_ACCESS_KEY_ID,
            secretAccessKey: config.secretAccessKey,//AWS_SECRET_ACCESS_KEY,
        });
        const s3 = new AWS.S3({
            params: { Bucket: "ava-edusimplified-public" },
            region: region,
        });

        const params = {
            Bucket: "ava-edusimplified-public",
            Key: `${fileName}.webm`,
            Body: file,
            ContentType: 'video/webm'
        };


        try {
            let result = await s3.upload(params).promise();
            if (result.Key) {
                console.log("S3 Result", result);
                setLoading(false); // Hide loader after upload
                setQuestionSubmittedFlag(true);
                await handleNextClick();
                console.log("S3 currentQuestionIndex", currentQuestionIndex);

                payload["answer_link"] = result.Key;
                // alert('Upload successful!');
                const responseResult = await submitAnswerLinkToAPI(payload);
                if (!responseResult) {
                    console.error("Answer not uploaded, something went wrong!");
                }
                if (currentQuestionIndex === questions.length - 1) {
                    setShowSuccessScore(true);
                    localStorage.removeItem('opening_users_id');
                    localStorage.removeItem('tenant_id');
                    localStorage.removeItem('applicant_id');
                }
                return true;
            }
        } catch (error: any) {
            console.error("error", error);
            // alert('Upload failed: ' + error.message);
        } finally {
            // setShowSuccessScore(true);
        }
    }

    const fetchData = async (blobUrl: string) => {
        try {
            console.log("fetchData blobUrl:-", blobUrl);

            const response = await axios.get(blobUrl || "", { responseType: 'blob' });
            const blobDataResult = response.data;
            if (blobDataResult) {
                return blobDataResult;
            }
            return "";
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        if (questions[currentQuestionIndex]) {
            setWaitTimerSeconds(questions[currentQuestionIndex].waitTime);

        }

        // if (waitTimerSeconds === 0 && status == 'recording') {
        //   setAnswerTimerSeconds(questions[currentQuestionIndex].answerTime);
        // }
    }, [currentQuestionIndex, questions]);

    useEffect(() => {
        const waitInterval = setInterval(() => {
            setWaitTimerSeconds((prev) => (prev > 0) ? prev - 1 : 0);
        }, 1000);

        const startRecordAfterTime = async () => {
            clearInterval(waitInterval);
            // Handle what to do when wait time is over
            await startRecordingAnswer();
        }

        if (waitTimerSeconds === 0) {
            // if (waitTimerSeconds === 0 && status == 'recording') {
            //   setAnswerTimerSeconds(questions[currentQuestionIndex].answerTime);
            // }
            startRecordAfterTime();
        }

        return () => clearInterval(waitInterval);
    }, [waitTimerSeconds]);

    // const answerTimerFn = async () => {
    // if (waitTimerSeconds === 0) {
    useEffect(() => {
        const answerInterval = setInterval(() => {
            setAnswerTimerSeconds((prev) => (prev > 0) ? prev - 1 : 0);
        }, 1000);

        const submitAnswerAfterTime = async () => {
            clearInterval(answerInterval);
            // Handle what to do when answer time is over
            await stopRecordingAnsAndSubmit();
        }
        if (answerTimerSeconds === 0) {
            submitAnswerAfterTime();
        }

        return () => clearInterval(answerInterval);
    }, [answerTimerSeconds]);
    // }
    // }


    const closePopup = () => {
        stopRecording();
        setShowSuccessScore(false);
        // setQuestionSubmittedFlag(false);
        // handleNextClick();
        navigate('/tickets');
    };

    const submitAnswerLinkToAPI = async (payload: any) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post('https://avaapi.flair-solution.com/api/save/answers', payload, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response?.data?.success) {
                return response.data;
            }
            // setResponse(response.data);
        }
        catch (error: any) {
            console.error(error);
            return null;
        }
    }

    return (
        <div className="container mt-4">
            {loading && ( // Show loader if loading state is true
                <div className="overlay">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            )}
            <section className="d-flex content-justify-center m-auto">
                <SideBar />
                <Card>
                    <div className="container">
                        <div className="row">
                            <>
                                {
                                    status === "recording" && loading === false ? (
                                        <h1 style={{ textAlign: 'center' }}>Start Answers</h1>
                                    ) :
                                        (<h1 style={{ textAlign: 'center' }}>Start Questions</h1>)
                                }
                            </>
                            {/* <>
                {
                  (
                    <h1 style={{ textAlign: 'center' }}>{status}</h1>
                  )
                }
              </> */}
                            <div className="col-md-6 col-sm-6">
                                {
                                    <>
                                        {
                                            status === "recording" && waitTimerSeconds === 0 && loading === false ?
                                                (
                                                    <div><h1 style={{ textAlign: 'center' }}>Answer Time: {answerTimerSeconds}</h1></div>
                                                ) :
                                                (
                                                    <div><h1 style={{ textAlign: 'center' }}>Wait Time: {waitTimerSeconds}</h1></div>
                                                )
                                        }
                                    </>
                                }
                                <Card.Body>
                                    <Card.Title>{formatQuestionNumber(currentQuestionIndex)}: {questions[currentQuestionIndex]?.question}</Card.Title>
                                </Card.Body>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                {/* <LiveQuiz /> */}
                                <div className="container py-3">
                                    <div className="camera-width-con">
                                        {
                                            status === 'recording' && waitTimerSeconds === 0 && loading === false ?
                                                (
                                                    <Webcam height={600} width={600} ref={webcamRef} />
                                                ) :
                                                (
                                                    questionSubmitted && loading === true ?
                                                        (
                                                            <img src={videoImg} />
                                                        ) :
                                                        (loading === false && (
                                                            <video src={questions[currentQuestionIndex]?.videoURL}
                                                                controls
                                                                autoPlay={true}
                                                            />
                                                        ))
                                                )
                                        }
                                    </div>
                                </div>
                                {/*  */}
                            </div>
                        </div>
                    </div>
                    <Card.Footer>
                        <div className="text-center mt-3">
                            <Button onClick={status === "recording" && waitTimerSeconds === 0 && loading === false ? stopRecordingAnsAndSubmit : startRecordingAnswer}>
                                {status === "recording" && waitTimerSeconds === 0 && loading === false ? 'Stop Recording & Submit' : 'Start Recording'}
                            </Button>
                        </div>
                    </Card.Footer>
                </Card>
            </section>
            <div className="row">
                <div className="col-md-12 col-sm-12 text-center">
                    {showScore && (
                        <div className="overlay">
                            <div className="popup" style={{ width: "450px" }}>
                                <div className="text-success py-3">
                                    <p className='text-left'> Congratulations! Your quiz has been successfully submitted.
                                        Thank you for participating and testing your knowledge.
                                        Results will be available shortly. Good luck! </p>
                                </div>
                                <div className="text-danger py-3">
                                </div>
                                <button
                                    className="btn btn-primary py-2"
                                    onClick={closePopup}
                                >Close</button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
