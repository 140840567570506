import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faVideoSlash, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons';
import '../assets/css/Dashboard.css';
import { SideBar } from './SideBar';

export const DashboardPage = () => {
    const [hasWebcam, setHasWebcam] = useState<boolean | null>(null);
    const [hasMic, setHasMic] = useState<boolean | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate(); 
    const [closePopup, setClosePopup] = useState(false);

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         if (navigator && navigator.mediaDevices) {
    //             navigator.mediaDevices.enumerateDevices().then(devices => {
    //                 const webcam = devices.some(device => device.kind === "videoinput");
    //                 const mic = devices.some(device => device.kind === "audioinput");

    //                 setHasWebcam(webcam);
    //                 setHasMic(mic);
    //                 setLoading(false); 

    //                 if (webcam) {
    //                     //navigate('/testQuiz');
    //                 }
    //                 else {
    //                   // navigate('/configError');
    //                 }
    //             });
    //         }
    //     }, 2000);

    //     return () => clearTimeout(timer); 
    // }, [navigate]);

    return (<div className='sidebar_container'>
        <SideBar />

        <div className={`overlay permission ${closePopup ? 'open' : ''}`}>
            <div className='col-md-6 m-auto'>
                <div className="popup">
                    <div className="permission-access">
                        <p><strong>Hi, Test User</strong></p>
                        <p>AVA needs access to your camera and microphone in order to capture your amazing talks!</p>
                        <div className="access_icon my-4">
                            <div className="sub_access_icon permission">
                                <FontAwesomeIcon icon={faVideoSlash} />
                            </div>
                            <div className="sub_access_icon permission">
                                <FontAwesomeIcon icon={faMicrophoneSlash} />
                            </div>
                        </div>

                        <div className="access_btn my-3">
                            <Link to="/system-config" style={{textDecoration:"none"}}>
                                <button className="btn" type="button">
                                    <FontAwesomeIcon icon={faVideo} />  Give access to camera and mic
                                </button>
                            </Link>
                        </div>
                        <div className="by-default-btn my-3">
                        <Link to="/configError">
                            <button className="btn" type="button">
                                Continue without  camera and  mic
                            </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

