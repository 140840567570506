import React, { useEffect, useState, useRef } from 'react';
import '../../assets/css/Landing.css'; // Import your CSS file
import image1 from "../../assets/images/image_1.webp"
import image2 from "../../assets/images/computer.png"
import web_logo from "../../assets/images/web_logo.svg"
import { toast } from "react-toastify";
import { Link, useNavigate } from 'react-router-dom';
export const HeaderComponent: React.FC = () => {
  const [scrolled, setScrolled] = useState(false);
  const navbarRef = useRef<HTMLDivElement | null>(null);
  //  const [isLoggedIn, setIsLoggedIn] = useState(Boolean(localStorage.getItem('token')));
  const [isLoggedIn, setIsLoggedIn] = useState(Boolean(localStorage.getItem('authToken')));
  const [isUserInfo, setIsUserInfo] = useState(Boolean(localStorage.getItem('UserInfo')));
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 40) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleToggle = () => {
    if (navbarRef.current) {
      const displayValue = navbarRef.current.style.display === 'none' || navbarRef.current.style.display === '' ? 'block' : 'none';
      navbarRef.current.style.display = displayValue;
    }
  };
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('authToken');
    localStorage.removeItem('UserInfo');
    const logOutMsg = 'Logout successful! Redirecting to Login Page...';
    toast.success(logOutMsg, {
    });

    setIsLoggedIn(false);
    navigate("/sign-in");
  };
  return (
    <header id="header" className={scrolled ? 'scrolled' : ''}>
      <div className="navbar-container">
        <div className="web-logo">
          <Link to="/">
            <img src={web_logo} alt="web-logo" />
          </Link>
        </div>
        <div>
          <nav className="" id="navbar" ref={navbarRef}>
            <ul>
              <li className="item"><Link to="/">Home</Link></li>
              {isLoggedIn ? (<>
                <li className="item">
                  <Link to="/tickets">Dashboard</Link></li>
                <li className="item com-btn login-btn">
                  <button onClick={handleLogout}>Logout</button>
                </li>
              </>) : (<>
                <li className="item com-btn login-btn">
                  <Link to="/sign-in">
                    <button>Log in</button>
                  </Link>
                </li>
                {/* <li className="item com-btn login-btn">
                    <Link to="/signup">
                      <button>Sign up</button>
                    </Link>
                  </li> */}
              </>)}
            </ul>
          </nav>
          <div className="toggle-btn" id="toggle-btn" onClick={handleToggle}>
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512">
              <path style={{ fill: "white" }} d="M16 132h416c8.8 0 16-7.2 16-16V76c0-8.8-7.2-16-16-16H16C7.2 60 0 67.2 0 76v40c0 8.8 7.2 16 16 16zm0 160h416c8.8 0 16-7.2 16-16v-40c0-8.8-7.2-16-16-16H16c-8.8 0-16 7.2-16 16v40c0 8.8 7.2 16 16 16zm0 160h416c8.8 0 16-7.2 16-16v-40c0-8.8-7.2-16-16-16H16c-8.8 0-16 7.2-16 16v40c0 8.8 7.2 16 16 16z" />
            </svg>
          </div>
        </div>
      </div>
    </header>
  );
};
//export default HeaderComponent;
