import React, { useState } from 'react';
import '../assets/css/sidebar.css';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { faVideoSlash } from '@fortawesome/free-solid-svg-icons';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { toast } from "react-toastify";
import webLogo from "../assets/images/ava_logo_black.png"

export const SideBar = () => {
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(Boolean(localStorage.getItem('authToken')));
    const [isUserInfo, setIsUserInfo] = useState(Boolean(localStorage.getItem('UserInfo')));
    const navigate = useNavigate();
    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };
    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('authToken');
        localStorage.removeItem('UserInfo');
        const logOutMsg = 'Logout successful! Redirecting to Login Page...';
        toast.success(logOutMsg, {
        });

        setIsLoggedIn(false);
        navigate("/sign-in");
    };
    return (<>
        <div className={`responsive-display ${isSidebarOpen ? 'open' : ''}`}>

            <Link to="#submenu1" data-bs-toggle="collapse" className="nav-link px-0 align-middle">
                <button className="toggle-btn show-hide-btn" onClick={toggleSidebar}>
                    <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} />
                </button>
            </Link>
        </div>
        <div className={`col-auto col-md-3 col-xl-2 px-sm-2 px-0 sidebar-container ${isSidebarOpen ? 'open' : ''}`}>
            <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                <Link to="/tickets" className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                    <img className='web-image' src={webLogo} alt='weblogo' style={{ width: "60px" }} />
                    <span className="fs-5 d-inline d-sm-inline text-dark">AVA</span>
                </Link>
                <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                    <li className="nav-item">
                        <Link to="/tickets" className="nav-link align-middle px-0">
                            <FontAwesomeIcon icon={faHome} />  <span className="ms-1 d-inline d-sm-inline">Home</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/liveQuiz" className="nav-link px-0 align-middle">
                            <FontAwesomeIcon icon={faChartBar} /> <span className="ms-1 d-inline d-sm-inline">Live</span> </Link>
                    </li>
                    <li>
                        <Link to="/practiceTest" className="nav-link px-0 align-middle">
                            <FontAwesomeIcon icon={faVideoSlash} /> <span className="ms-1 d-inline d-sm-inline">Practice</span> </Link>
                    </li>
                    {/* <li>
                        <Link to="/test-practice" className="nav-link px-0 align-middle">
                            <FontAwesomeIcon icon={faVideoSlash} /> <span className="ms-1 d-inline d-sm-inline">Test Practice</span> </Link>
                    </li> */}
                    <li>
                        <Link to="/UserFromEmail" className="nav-link px-0 align-middle">
                            <FontAwesomeIcon icon={faVideoSlash} /> <span className="ms-1 d-inline d-sm-inline">User From Email</span> </Link>
                    </li>
                </ul>
                <hr />
                <div className="dropdown pb-4">
                    <Link to="#" className="d-flex align-items-center text-decoration-none dropdown-toggle gap-2" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                        <FontAwesomeIcon icon={faUser} />
                        <span className="d-inline d-sm-inline mx-1 text-dark">Test</span>
                    </Link>
                    <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
                        <li><Link className="dropdown-item" to="#">Profile</Link></li>
                        <li>
                            <hr className="dropdown-divider" />
                        </li>
                        <li><Link className="dropdown-item" to="/sign-in"
                            onClick={handleLogout}
                        >Sign out</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    </>);
};

// export default Sidebar;
