

// import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
// import axios from 'axios';
// import { toast } from 'react-toastify';
// import { Link } from 'react-router-dom';
// import { CreateUserApis } from '../api/CreateUserApis'; // Adjust the path as necessary

// export default function QuizDetailPage() {
//     const [email, setEmail] = useState('');
//     const [name, setName] = useState('');
//     const [tenant_id, setTenantId] = useState('');
//     const [opening_id, setOpeningId] = useState('');
//     const [shortDescription, setShortDescription] = useState('');
//     const [description, setDescription] = useState('');
//     const [internalTitle, setInternalTitle] = useState('');
//     const [externalTitle, setExternalTitle] = useState('');
//     const [logo, setLogo] = useState('');
//     const [company_name, setCompany_name] = useState('');
//     const [externalink, setExternallink] = useState('');
//     const [valid_till, setValid_till] = useState('');
//     const [valid_from, setValid_from] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [hide_form, setHide_form] = useState(false);
//     const [error, setError] = useState<string>('');

//     const openingIdFromLocalStorage = localStorage.getItem('openingId');

//     useEffect(() => {
//         axios.get(`https://avaapi.flair-solution.com/api/opening/detail/${openingIdFromLocalStorage}`)
//             .then(response => {
//                 const data = response.data.data;
//                 setTenantId(data.tenant_id.toString());
//                 setOpeningId(data.id.toString());
//                 setShortDescription(data.short_description);
//                 setDescription(data.description);
//                 setInternalTitle(data.internal_title);
//                 setLogo(data.logo_path);
//                 setExternallink(data.link);
//                 setCompany_name(data.name);
//                 setExternalTitle(data.external_title);
//                 setValid_till(data.formatted_valid_till);
//                 setValid_from(data.formatted_valid_from);
//             })
//             .catch(error => {
//                 console.error('Error fetching data:', error);
//             });
//     }, [openingIdFromLocalStorage]);

//     const handleSignUp = async (e: FormEvent<HTMLFormElement>) => {
//         e.preventDefault();

//         if (!email || !name) {
//             toast.error('Please fill out all required fields.');
//             return;
//         }

//         setLoading(true);

//         try {
//             const result = await CreateUserApis(email, name, tenant_id, opening_id);

//             if (result.success) {
//                 const { opening_users_id, token, message } = result;
//                 const { id } = result.data;

//                 localStorage.setItem('opening_users_id', opening_users_id);
//                 localStorage.setItem('token', token);
//                 localStorage.setItem('applicant_id', id);
//                 localStorage.setItem('logo', logo);
//                 localStorage.setItem('tenant_id', tenant_id);
//                 localStorage.setItem('externalTitle', externalTitle);

//                 toast.success(message);
//                 setHide_form(true);
//             } else {
//                 setError(result.message); // Assuming your API returns a specific message
//                 toast.error(result.message);
//             }

//         } catch (error) {
//             console.error('Error during signup:', error);
//             setError('Sign up failed...');
//             toast.error('Sign up failed...');
//         }

//         setLoading(false);
//         setEmail('');
//         setName('');
//     };

//     const handleOpeningIdChange = (e: ChangeEvent<HTMLInputElement>) => {
//         setOpeningId(e.target.value);
//     };

//     const handleTenantIdChange = (e: ChangeEvent<HTMLInputElement>) => {
//         setTenantId(e.target.value);
//     };

//     return (
//         <>
//             <section>
//                 <div className="row">
//                     <div className="container-fluid col-12">
//                         <div className="">
//                             <div className="row justify-content-center align-items-center detail_container_box">
//                                 <div className="col-sm-12 col-md-8 col-lg-8 form-continer">
//                                     <div className="start-form">
//                                         <Link to="/allopenings" title="Back">
//                                             <button type="button" className="btn theme_btn btn-primary center-button" data-bs-dismiss="modal">
//                                                 <svg xmlns="http://www.w3.org/2000/svg" height="12" width="10.5" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
//                                                 &nbsp;Back
//                                             </button>
//                                         </Link>
//                                         <div className='my-2 detail_elem'>
//                                             <label className='detail-label'>External Title:</label>
//                                             &nbsp;&nbsp; <div>{externalTitle}</div>
//                                         </div>
//                                         <div className='my-2 detail_elem'>
//                                             <label className='detail-label'>Internal Title:</label>
//                                             &nbsp;&nbsp; <div>{internalTitle}</div>
//                                         </div>
//                                         <div className='my-2 detail_elem'>
//                                             <label className='detail-label'>Description:</label>
//                                             &nbsp;&nbsp;  <div>{description}</div>
//                                         </div>
//                                         <div className='my-2'>
//                                             <img
//                                                 src={`https://ava-edusimplified-public.s3.ap-south-1.amazonaws.com/${logo}`}
//                                                 alt="Company Logo"
//                                                 className="card-img-top"
//                                                 width="250px"
//                                                 height="120px"
//                                             />
//                                         </div>
//                                         <div className='my-2 detail_elem'>
//                                             <label className='detail-label'>Company Name:</label>
//                                             &nbsp;&nbsp;  <div>{company_name}</div>
//                                         </div>
//                                         <div className='my-2 detail_elem'>
//                                             <label className='detail-label'>Valid from:</label>
//                                             &nbsp;&nbsp;  <div>{valid_from}</div>
//                                         </div>
//                                         <div className='my-2 detail_elem'>
//                                             <label className='detail-label'>Valid Till:</label>
//                                             &nbsp;&nbsp;  <div>{valid_till}</div>
//                                         </div>
//                                         <div className='my-2 detail_elem'>
//                                             <label className='detail-label'>External Link:</label>
//                                             &nbsp;&nbsp;<span><a href={externalink}>External Link</a></span>
//                                         </div>
//                                         {loading ? (
//                                             <svg
//                                                 className='svg-styles'
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                                 viewBox="0 0 100 100"
//                                                 preserveAspectRatio="xMidYMid"
//                                                 width="100"
//                                                 height="100"
//                                                 style={{ shapeRendering: 'auto', display: 'block', background: 'transparent' }}
//                                                 xmlnsXlink="http://www.w3.org/1999/xlink"
//                                             >
//                                                 <g>
//                                                     <circle r="20" fill="#e90c59" cy="50" cx="30">
//                                                         <animate begin="-0.7142857142857142s" values="30;70;30" keyTimes="0;0.5;1" dur="1.4285714285714284s" repeatCount="indefinite" attributeName="cx"></animate>
//                                                     </circle>
//                                                     <circle r="20" fill="#46dff0" cy="50" cx="70">
//                                                         <animate begin="0s" values="30;70;30" keyTimes="0;0.5;1" dur="1.4285714285714284s" repeatCount="indefinite" attributeName="cx"></animate>
//                                                     </circle>
//                                                     <circle r="20" fill="#e90c59" cy="50" cx="30">
//                                                         <animate begin="-0.7142857142857142s" values="30;70;30" keyTimes="0;0.5;1" dur="1.4285714285714284s" repeatCount="indefinite" attributeName="cx"></animate>
//                                                         <animate repeatCount="indefinite" dur="1.4285714285714284s" keyTimes="0;0.499;0.5;1" calcMode="discrete" values="0;0;1;1" attributeName="fill-opacity"></animate>
//                                                     </circle>
//                                                 </g>
//                                             </svg>
//                                         ) : (
//                                             <div>
//                                                 {hide_form ? (
//                                                     <h4 className='checkyour_email'>Please Check Your Email</h4>
//                                                 ) : (
//                                                     <form id="register_form" onSubmit={handleSignUp}>
//                                                         {error && <p className="text-danger">{error}</p>} {/* Display error message */}
//                                                         <div className="mb-3 mt-2">
//                                                             <label htmlFor="email" className="form-label detail-label">User Registration:</label>
//                                                             <input
//                                                                 id="email"
//                                                                 type="email"
//                                                                 placeholder="Email"
//                                                                 className="form-control form-input text-black"
//                                                                 name="email"
//                                                                 value={email}
//                                                                 onChange={(e) => setEmail(e.target.value)}
//                                                                 required
//                                                             />
//                                                             <input
//                                                                 id="name"
//                                                                 type="text"
//                                                                 placeholder="Name"
//                                                                 className="form-control form-input text-black fname"
//                                                                 name="name"
//                                                                 value={name}
//                                                                 onChange={(e) => setName(e.target.value)}
//                                                                 required
//                                                             />
//                                                             <input
//                                                                 id="tenant_id"
//                                                                 type="hidden"
//                                                                 placeholder="tenant_id"
//                                                                 className="form-control form-input text-black"
//                                                                 name="tenant_id"
//                                                                 value={tenant_id}
//                                                                 onChange={handleOpeningIdChange}
//                                                             />
//                                                             <input
//                                                                 id="opening_id"
//                                                                 type="hidden"
//                                                                 placeholder="opening_id"
//                                                                 className="form-control form-input text-black"
//                                                                 name="opening_id"
//                                                                 value={opening_id}
//                                                                 onChange={handleTenantIdChange}
//                                                             />
//                                                         </div>
//                                                         <div className="mb-3">
//                                                             <button
//                                                                 type="submit"
//                                                                 className="btn btn-primary mb-2 center-button"
//                                                                 title="Continue"
//                                                             >
//                                                                 Continue
//                                                             </button>
//                                                         </div>
//                                                     </form>
//                                                 )}
//                                             </div>
//                                         )}
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// }






import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import { CreateUserApis } from '../api/CreateUserApis'; // Adjust the path as necessary

export default function QuizDetailPage() {
    const { openingId } = useParams<{ openingId: string }>();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [tenant_id, setTenantId] = useState('');
    const [opening_id, setOpeningId] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [description, setDescription] = useState('');
    const [internalTitle, setInternalTitle] = useState('');
    const [externalTitle, setExternalTitle] = useState('');
    const [logo, setLogo] = useState('');
    const [company_name, setCompany_name] = useState('');
    const [externalink, setExternallink] = useState('');
    const [valid_till, setValid_till] = useState('');
    const [valid_from, setValid_from] = useState('');
    const [loading, setLoading] = useState(false);
    const [hide_form, setHide_form] = useState(false);
    const [error, setError] = useState<string>('');

    useEffect(() => {
        if (openingId) {
            axios.get(`https://avaapi.flair-solution.com/api/opening/detail/${openingId}`)
                .then(response => {
                    const data = response.data.data;
                    setTenantId(data.tenant_id.toString());
                    setOpeningId(data.id.toString());
                    setShortDescription(data.short_description);
                    setDescription(data.description);
                    setInternalTitle(data.internal_title);
                    setLogo(data.logo_path);
                    setExternallink(data.link);
                    setCompany_name(data.name);
                    setExternalTitle(data.external_title);
                    setValid_till(data.formatted_valid_till);
                    setValid_from(data.formatted_valid_from);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [openingId]);

    const handleSignUp = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!email || !name) {
            toast.error('Please fill out all required fields.');
            return;
        }

        setLoading(true);

        try {
            const result = await CreateUserApis(email, name, tenant_id, opening_id);

            if (result.success) {
                const { opening_users_id, token, message } = result;
                const { id } = result.data;

                localStorage.setItem('opening_users_id', opening_users_id);
                localStorage.setItem('token', token);
                localStorage.setItem('applicant_id', id);
                localStorage.setItem('logo', logo);
                localStorage.setItem('tenant_id', tenant_id);
                localStorage.setItem('externalTitle', externalTitle);

                toast.success(message);
                setHide_form(true);
            } else {
                setError(result.message); // Assuming your API returns a specific message
                toast.error(result.message);
            }

        } catch (error) {
            console.error('Error during signup:', error);
            setError('Sign up failed...');
            toast.error('Sign up failed...');
        }

        setLoading(false);
        setEmail('');
        setName('');
    };

    const handleOpeningIdChange = (e: ChangeEvent<HTMLInputElement>) => {
        setOpeningId(e.target.value);
    };

    const handleTenantIdChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTenantId(e.target.value);
    };

    return (
        <>
            <section>
                <div className="row">
                    <div className="container-fluid col-12">
                        <div className="">
                            <div className="row justify-content-center align-items-center detail_container_box">
                                <div className="col-sm-12 col-md-8 col-lg-8 form-continer">
                                    <div className="start-form">
                                        <Link to="/allopenings" title="Back">
                                            <button type="button" className="btn theme_btn btn-primary center-button" data-bs-dismiss="modal">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="12" width="10.5" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
                                                &nbsp;Back
                                            </button>
                                        </Link>
                                        <div className='my-2 detail_elem'>
                                            <label className='detail-label'>External Title:</label>
                                            &nbsp;&nbsp; <div>{externalTitle}</div>
                                        </div>
                                        <div className='my-2 detail_elem'>
                                            <label className='detail-label'>Internal Title:</label>
                                            &nbsp;&nbsp; <div>{internalTitle}</div>
                                        </div>
                                        <div className='my-2 detail_elem'>
                                            <label className='detail-label'>Description:</label>
                                            &nbsp;&nbsp;  <div>{description}</div>
                                        </div>
                                        <div className='my-2'>
                                            <img
                                                src={`https://ava-edusimplified-public.s3.ap-south-1.amazonaws.com/${logo}`}
                                                alt="Company Logo"
                                                className="card-img-top"
                                                width="250px"
                                                height="120px"
                                            />
                                        </div>
                                        <div className='my-2 detail_elem'>
                                            <label className='detail-label'>Company Name:</label>
                                            &nbsp;&nbsp;  <div>{company_name}</div>
                                        </div>
                                        <div className='my-2 detail_elem'>
                                            <label className='detail-label'>Valid from:</label>
                                            &nbsp;&nbsp;  <div>{valid_from}</div>
                                        </div>
                                        <div className='my-2 detail_elem'>
                                            <label className='detail-label'>Valid Till:</label>
                                            &nbsp;&nbsp;  <div>{valid_till}</div>
                                        </div>
                                        <div className='my-2 detail_elem'>
                                            <label className='detail-label'>External Link:</label>
                                            &nbsp;&nbsp;<span><a href={externalink}>External Link</a></span>
                                        </div>
                                        {loading ? (
                                            <svg
                                                className='svg-styles'
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 100 100"
                                                preserveAspectRatio="xMidYMid"
                                                width="100"
                                                height="100"
                                                style={{ shapeRendering: 'auto', display: 'block', background: 'transparent' }}
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                            >
                                                <g>
                                                    <circle r="20" fill="#e90c59" cy="50" cx="30">
                                                        <animate begin="-0.7142857142857142s" values="30;70;30" keyTimes="0;0.5;1" dur="1.4285714285714284s" repeatCount="indefinite" attributeName="cx"></animate>
                                                    </circle>
                                                    <circle r="20" fill="#46dff0" cy="50" cx="70">
                                                        <animate begin="0s" values="30;70;30" keyTimes="0;0.5;1" dur="1.4285714285714284s" repeatCount="indefinite" attributeName="cx"></animate>
                                                    </circle>
                                                    <circle r="20" fill="#e90c59" cy="50" cx="30">
                                                        <animate begin="-0.7142857142857142s" values="30;70;30" keyTimes="0;0.5;1" dur="1.4285714285714284s" repeatCount="indefinite" attributeName="cx"></animate>
                                                        <animate repeatCount="indefinite" dur="1.4285714285714284s" keyTimes="0;0.499;0.5;1" calcMode="discrete" values="0;0;1;1" attributeName="fill-opacity"></animate>
                                                    </circle>
                                                </g>
                                            </svg>
                                        ) : (
                                            <div>
                                                {hide_form ? (
                                                    <h4 className='checkyour_email'>Please Check Your Email</h4>
                                                ) : (
                                                    <form id="register_form" onSubmit={handleSignUp}>
                                                        {error && <p className="text-danger">{error}</p>} {/* Display error message */}
                                                        <div className="mb-3 mt-2">
                                                            <label htmlFor="email" className="form-label detail-label">User Registration:</label>
                                                            <input
                                                                id="email"
                                                                type="email"
                                                                placeholder="Email"
                                                                className="form-control form-input text-black"
                                                                name="email"
                                                                value={email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                required
                                                            />
                                                            <input
                                                                id="name"
                                                                type="text"
                                                                placeholder="Name"
                                                                className="form-control form-input text-black fname"
                                                                name="name"
                                                                value={name}
                                                                onChange={(e) => setName(e.target.value)}
                                                                required
                                                            />
                                                            <input
                                                                id="tenant_id"
                                                                type="hidden"
                                                                placeholder="tenant_id"
                                                                className="form-control form-input text-black"
                                                                name="tenant_id"
                                                                value={tenant_id}
                                                                onChange={handleOpeningIdChange}
                                                            />
                                                            <input
                                                                id="opening_id"
                                                                type="hidden"
                                                                placeholder="opening_id"
                                                                className="form-control form-input text-black"
                                                                name="opening_id"
                                                                value={opening_id}
                                                                onChange={handleTenantIdChange}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary mb-2 center-button"
                                                                title="Continue"
                                                            >
                                                                Continue
                                                            </button>
                                                        </div>
                                                    </form>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
