import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "./styles.css";
import { toast } from "react-toastify";
import { UserSignupAPIs } from './../api/UserSignupAPIs';

export const EmailSignUp = () => {
    // const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    // const [password, setPassword] = useState<string>("");
    // const [phone, setPhone] = useState<string>("");
    // const [collegeName, setCollegeName] = useState<string>("");
    // const [error, setError] = useState<string | null>(null);
    // const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    // const handleSignUp = async () => {
    const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            // const result = await UserSignupAPIs(name, email, password, phone, collegeName);
            const result = await UserSignupAPIs(email);
            console.log('Signup successful:', result);
            const successMessage = 'Sign up successful!...';
            toast.success(successMessage, {
            });
        } catch (error) {
            console.error('Error during signup:', error);
            const errorMessage = 'Sign up failed...';
            toast.error(errorMessage, {
            });
        }
    };


    return (<>
        <section>
            <div className="container-fluid">
                <div className="container_sign">

                    <div
                        className="row justify-content-center align-items-center"
                        style={{ height: "100vh" }}
                    >
                        <div className="col-sm-12 col-md-6 col-lg-6 form-continer">
                            <div className="start-form">
                                <form onClick={handleSignUp}>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email address</label>
                                        <input
                                            id="email"
                                            type="email"
                                            placeholder="Email"
                                            className="form-control form-input text-black"
                                            name="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                            autoFocus
                                        />

                                    </div>
                                   

                                    <div className="mb-3">
                                        <button
                                            type="submit"
                                            className="btn btn-primary mb-3" title="Continue">Continue</button>
                                    </div>
                                    <div className="text-center">
                                        <h6 className=""> Already have an account?  <Link to="/sign-in" title="Sign up here!">Login!</Link></h6>
                                    </div>
                                </form>
                                {/* {showSuccessMessage && (
                                    <div className="alert alert-success" role="alert">
                                        <h4>  Sign up successful! Redirecting...</h4>
                                    </div>
                                )} */}
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 right_section">
                            <div className="form-right-img">
                                <img src="https://storage.googleapis.com/yoodli-public/onboarding-assets/presentation1.png" alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>);
}

//export default EmailForm;

