import { Link } from "react-router-dom"
export const OpeningUI = () => {
    return (
        <div className="container py-md-5">
            <div className="card card-body">
                <h2 className="text-center py-3">Company Profile</h2>
                <div className="table-responsive-sm p-md-3">
                    <table className="table table-bordered table-hover">
                        <tbody className="thead-dark">
                            <tr>
                                <th scope="row">Company Name</th>
                                <td>Flair IT Solution</td>
                            </tr>
                            <tr>
                                <th scope="row">Industry</th>
                                <td>IT</td>
                            </tr>
                            <tr>
                                <th scope="row">Description</th>
                                <td>We are looking for a highly skilled and creative Front-End Developer to join our team. 
                                    The ideal candidate will have a strong understanding of HTML, CSS, and JavaScript,
                                     as well as experience with popular front-end frameworks and libraries. 
                                     They will be responsible for designing, developing, and maintaining user interfaces 
                                     for our web applications.
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Responsibilities</th>
                                <td>- Design and develop user interfaces using HTML, CSS, and JavaScript<br />
                                    - Collaborate with the back-end team to ensure seamless integration of front-end components <br />
                                    - Utilize popular front-end frameworks and libraries such as React, Angular, or Vue.js <br />
                                    - Ensure cross-browser compatibility and responsiveness of web applications <br />
                                    - Optimize web applications for performance and security <br />
                                    - Participate in code reviews and provide constructive feedback to peers <br />
                                    - Stay up-to-date with the latest industry trends and best practices <br />
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Requirements</th>
                                <td> - A bachelor's degree in computer science, software engineering, or a related field <br />
                                    - Strong knowledge of HTML, CSS, and JavaScript <br />
                                    - Experience with popular front-end frameworks and libraries <br />
                                    - Familiarity with responsive web design and mobile-first development <br />
                                    - Excellent problem-solving skills and attention to detail <br />
                                    - Strong communication skills and the ability to work effectively in a team environment <br />
                                </td>
                            </tr>

                            <tr>
                                <th scope="row">Salary</th>
                                <td> Up to 3 lakh per annum.</td>
                            </tr>
                            <tr>
                                <th scope="row">Website</th>
                                <td><a href="#">www.flair-solution.com</a></td>
                            </tr>
                            <tr>
                                <th scope="row">Contact Email</th>
                                <td>contact@flair-solution.com</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="apply-link text-center py-3 my-3">
                        <Link
                            to=""
                            className="btn btn-primary"
                        >Apply</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}