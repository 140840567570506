

import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import "../assets/css/tickets.css";
import "./styles.css";
import userIcon from "../assets/images/icons-user.png";
import { SideBar } from './SideBar';
import videoImage from "../assets/images/defult-Video.png";
import { Link, useNavigate } from 'react-router-dom';
import { getOpeningAPI } from "../api/getQuizListAPI";
import axios from 'axios';
import WebcamAccess from './Webcamaccess';

interface ExtendedNavigator extends Navigator {
    connection?: {
        effectiveType?: string;
    };
    mozConnection?: {
        effectiveType?: string;
    };
    webkitConnection?: {
        effectiveType?: string;
    };
}

export const Allopenings: React.FC = () => {
    const userInfo = localStorage.getItem('UserInfo');
    const [openingData, setOpeningData] = useState<any>(null);
    const [isChecked, setIsChecked] = useState(false);
    const [openings, setOpenings] = useState<Opening[]>([]);
    const [cameraAccess, setCameraAccess] = useState<boolean | null>(null);
    const [isMobile, setIsMobile] = useState(false);
    const [isSlowConnection, setIsSlowConnection] = useState(false);

    interface Opening {
        id: number;
        external_title: string;
        internal_title: string;
        logo_path: string;
        description: string;
        valid_from: string;
        formatted_valid_till: string;
        formatted_valid_from: string;
        name: string;
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://avaapi.flair-solution.com/api/opening/list');
                const data = response.data;
                if (data.success) {
                    setOpenings(data.data);
                } else {
                    console.error('Error: API request unsuccessful');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    function saveOpeningIdToLocal(id: string) {
        localStorage.setItem('openingId', id);
        console.log('openingId' + id);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getOpeningAPI();
                console.log("-----data", data);
                setOpeningData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    // useEffect(() => {
    //     const checkCameraAccess = async () => {
    //         try {
    //             const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    //             setCameraAccess(true);
    //             stream.getTracks().forEach(track => track.stop());
    //         } catch (error) {
    //             setCameraAccess(false);
    //         }
    //     };

    //     checkCameraAccess();
    // }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 769);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const checkConnectionSpeed = () => {
            const navigator = window.navigator as ExtendedNavigator;
            const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
            if (connection) {
                const { effectiveType } = connection;
                if (effectiveType === '2g' || effectiveType === 'slow-2g' || effectiveType === '3g') {
                    setIsSlowConnection(true);
                }
            }
        };

        checkConnectionSpeed();

        window.addEventListener('online', checkConnectionSpeed);
        window.addEventListener('offline', checkConnectionSpeed);

        return () => {
            window.removeEventListener('online', checkConnectionSpeed);
            window.removeEventListener('offline', checkConnectionSpeed);
        };
    }, []);





    useEffect(() => {
        const checkConnectionSpeed = () => {
            const navigator = window.navigator as ExtendedNavigator;
            const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
            if (connection) {
                const { effectiveType } = connection;
                console.log(`Connection type: ${effectiveType}`); // Log connection type
                if (effectiveType === '2g' || effectiveType === 'slow-2g' || effectiveType === '3g') {
                    setIsSlowConnection(true);
                    console.log('Connection is slow');
                } else {
                    setIsSlowConnection(false);
                    console.log('Connection is not slow');
                }
            } else {
                console.log('Connection API not supported');
            }
        };

        checkConnectionSpeed();

        window.addEventListener('online', checkConnectionSpeed);
        window.addEventListener('offline', checkConnectionSpeed);

        return () => {
            window.removeEventListener('online', checkConnectionSpeed);
            window.removeEventListener('offline', checkConnectionSpeed);
        };
    }, []);








    // if (cameraAccess === null) {
    //     return <div>Loading...</div>;
    // }

    // if (cameraAccess === false) {
    //     return (
    //         <div className="enablecamera">
    //             <div className='box-above-background'>
    //                 <div><img src="https://d34hmiuaex7c0.cloudfront.net/5159/uploads/page_featured_img_1721389891485.PNG" width={"350px"} /></div>
    //                 <p className='camera_error'> Camera access is required to view this content. Please enable camera permissions and try again.</p>
    //             </div>
    //         </div>
    //     );
    // }

    if (isSlowConnection) {
        return (
            <div className="enablecamera">
                <div className='box-above-background'>
                    <div><img src="https://d34hmiuaex7c0.cloudfront.net/5159/uploads/page_featured_img_1721389397601.jpg" width={"350px"} /></div>
                    <p className='camera_error'> Your internet connection is too slow. Please try again with a faster connection.</p>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className='sidebar_container'>
                <div className='container-fluid pt-3'>
                    <div className="container">
                        <div className="row row-cols-1 row-cols-md-3 g-4">
                            {openings.map(opening => (
                                <div className="col" key={opening.id}>
                                    <div className="card h-100">
                                        <div className='d-flex justify-content-between align-items-baseline pb-3'>
                                            <h6 className="card-title">{opening.internal_title}</h6>
                                        </div>
                                        <img
                                            src={`https://ava-edusimplified-public.s3.ap-south-1.amazonaws.com/${opening.logo_path}`}
                                            alt="Company Logo"
                                            className="card-img-top"
                                            width="100px"
                                            height="100px"
                                        />
                                        <div className="card-body">
                                            <div className='d-flex justify-content-between align-items-baseline'>
                                                <h6 className="card-title mb-0 pb-1">Company Name</h6>
                                                <p className="card-text mb-0 pb-1">{opening.name}</p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-baseline'>
                                                <h6 className="card-title mb-0 pb-1">Job Title</h6>
                                                <p className="card-text mb-0 pb-1">{opening.external_title}</p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-baseline'>
                                                <h6 className="card-title mb-0 pb-1">Description:</h6>
                                                &nbsp;&nbsp; <p className="card-text mb-0 pb-1">{opening.description}</p>
                                            </div>
                                            <br />
                                            <div className='d-flex justify-content-between align-items-baseline'>
                                                <h6 className="card-title mb-0 pb-1">Valid From</h6>
                                                <p className="card-text mb-0 pb-1">{opening.formatted_valid_from}</p>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-baseline'>
                                                <h6 className="card-title mb-0 pb-1">Valid Till</h6>
                                                <p className="card-text mb-0 pb-1">{opening.formatted_valid_till}</p>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <Link to={`/quizdetailpage/${opening.id}`} title="Test Practice!">
                                                <button type="button" onClick={() => saveOpeningIdToLocal(opening.id.toString())} className="btn theme_btn btn-primary" data-bs-dismiss="modal">
                                                    Start<svg className='svg_icons' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                        <path d="M3.4 81.7c-7.9 15.8-1.5 35 14.3 42.9L280.5 256 17.7 387.4C1.9 395.3-4.5 414.5 3.4 430.3s27.1 22.2 42.9 14.3l320-160c10.8-5.4 17.7-16.5 17.7-28.6s-6.8-23.2-17.7-28.6l-320-160c-15.8-7.9-35-1.5-42.9 14.3z" />
                                                    </svg>
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="all_model">
                            <div
                                className="modal fade"
                                id="exampleModalOne"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                                aria-labelledby="exampleModalOneLabel"
                                aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            <p className="text-left">Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                            <p className="text-left">Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                            <p className="text-left">Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                            <p className="text-left">Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                            <p className="text-left">Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                            <p className="text-left">Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="all_model">
                            <div
                                className="modal fade"
                                id="exampleModalTwo"
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                                aria-labelledby="exampleModalTowLabel"
                                aria-hidden="true">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            <p className="text-left">Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                            <p className="text-left">Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                            <p className="text-left">Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                            <p className="text-left">Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                            <p className="text-left">Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                            <div className='text-left'>
                                                <input
                                                    type="checkbox"
                                                    aria-label="Checkbox for following text input"
                                                    checked={isChecked}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label htmlFor="" className='px-2'>Accept Term & Condition</label>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                            {isChecked ? (
                                                <Link to="/testQuiz" title="Test Practice!">
                                                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal">
                                                        Start
                                                    </button>
                                                </Link>
                                            ) : (
                                                <span>
                                                    <button type="button" className="btn btn-primary" disabled>
                                                        Start
                                                    </button>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mobile-warning">
                <div className="">
                    <div className='mobile-box-above-background'>
                        <div><img src="https://d34hmiuaex7c0.cloudfront.net/5159/uploads/page_featured_img_1721389891485.PNG" width={"350px"} /></div>
                        <p className='mobile_availablity_error'> Sorry, this website is not available on mobile devices. Please use a tablet or PC.</p>
                    </div>
                </div>
            </div>
        </>
    );
};






// import React, { useState, useEffect } from 'react';
// import { Button, Modal } from 'react-bootstrap';
// import "../assets/css/tickets.css";
// import "./styles.css";
// import userIcon from "../assets/images/icons-user.png"
// import { SideBar } from './SideBar';
// import videoImage from "../assets/images/defult-Video.png"
// import { Link, useNavigate } from 'react-router-dom';
// import { getOpeningAPI } from "../api/getQuizListAPI";
// import axios from 'axios';
// import WebcamAccess from './Webcamaccess';

// export const Allopenings: React.FC = () => {
//     const userInfo = localStorage.getItem('UserInfo');
//     const [openingData, setOpeningData] = useState<any>(null);
//     const [isChecked, setIsChecked] = useState(false);
//     const [openings, setOpenings] = useState<Opening[]>([]);
//     const [cameraAccess, setCameraAccess] = useState<boolean | null>(null); // State for camera access status
//     const [isMobile, setIsMobile] = useState(false);

//     interface Opening {
//         id: number;
//         external_title: string;
//         internal_title: string;
//         logo_path: string;
//         description: string;
//         valid_from: string;
//         formatted_valid_till: string;
//         formatted_valid_from: string;
//         name: string;
//     }

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await axios.get('https://avaapi.flair-solution.com/api/opening/list');
//                 const data = response.data;
//                 if (data.success) {
//                     setOpenings(data.data);
//                 } else {
//                     console.error('Error: API request unsuccessful');
//                 }
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };

//         fetchData();
//     }, []);

//     const handleCheckboxChange = () => {
//         setIsChecked(!isChecked);
//     };

//     function saveOpeningIdToLocal(id: string) {
//         localStorage.setItem('openingId', id);
//         console.log('openingId' + id)
//     }

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const data = await getOpeningAPI();
//                 console.log("-----data", data)
//                 setOpeningData(data);
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };

//         fetchData();
//     }, []);

//     // Check for camera access when the component mounts
//     useEffect(() => {
//         const checkCameraAccess = async () => {
//             try {
//                 const stream = await navigator.mediaDevices.getUserMedia({ video: true });
//                 setCameraAccess(true);
//                 stream.getTracks().forEach(track => track.stop()); // Stop the video stream to release the camera
//             } catch (error) {
//                 setCameraAccess(false);
//             }
//         };

//         checkCameraAccess();
//     }, []);


//     // is mobile check
//     useEffect(() => {
//         // Function to check if the screen width is less than 769px
//         const handleResize = () => {
//             setIsMobile(window.innerWidth < 769);
//         };

//         // Initial check on component mount
//         handleResize();

//         // Event listener to update state when the window is resized
//         window.addEventListener('resize', handleResize);

//         // Cleanup the event listener on component unmount
//         return () => {
//             window.removeEventListener('resize', handleResize);
//         };
//     }, []);








//     // Render content based on camera access status
//     if (cameraAccess === null) {
//         return <div>Loading...</div>; // While checking for camera access
//     }

//     if (cameraAccess === false) {
//         return <div>Camera access is required to view this content. Please enable camera permissions and try again.</div>;
//     }










//     return (
//         <>
//             <div className='sidebar_container'>
//                 <div className='container-fluid pt-3'>
//                     <div className="container">
//                         <div className="row row-cols-1 row-cols-md-3 g-4">
//                             {openings.map(opening => (
//                                 <div className="col" key={opening.id}>
//                                     <div className="card h-100">
//                                         <div className='d-flex justify-content-between align-items-baseline pb-3'>
//                                             <h6 className="card-title">{opening.internal_title}</h6>
//                                         </div>
//                                         <img
//                                             src={`https://ava-edusimplified-public.s3.ap-south-1.amazonaws.com/${opening.logo_path}`}
//                                             alt="Company Logo"
//                                             className="card-img-top"
//                                             width="100px"
//                                             height="100px"
//                                         />
//                                         <div className="card-body">
//                                             <div className='d-flex justify-content-between align-items-baseline'>
//                                                 <h6 className="card-title mb-0 pb-1">Company Name</h6>
//                                                 <p className="card-text mb-0 pb-1">{opening.name}</p>
//                                             </div>
//                                             <div className='d-flex justify-content-between align-items-baseline'>
//                                                 <h6 className="card-title mb-0 pb-1">Job Title</h6>
//                                                 <p className="card-text mb-0 pb-1">{opening.external_title}</p>
//                                             </div>
//                                             <div className='d-flex justify-content-between align-items-baseline'>
//                                                 <h6 className="card-title mb-0 pb-1">Description:</h6>
//                                                 &nbsp;&nbsp; <p className="card-text mb-0 pb-1">{opening.description}</p>
//                                             </div>
//                                             <br />
//                                             <div className='d-flex justify-content-between align-items-baseline'>
//                                                 <h6 className="card-title mb-0 pb-1">Valid From</h6>
//                                                 <p className="card-text mb-0 pb-1">{opening.formatted_valid_from}</p>
//                                             </div>
//                                             <div className='d-flex justify-content-between align-items-baseline'>
//                                                 <h6 className="card-title mb-0 pb-1">Valid Till</h6>
//                                                 <p className="card-text mb-0 pb-1">{opening.formatted_valid_till}</p>
//                                             </div>
//                                         </div>
//                                         <div className="card-footer">
//                                             <Link to="/quizdetailpage" title="Test Practice!">
//                                                 <button type="button" onClick={() => saveOpeningIdToLocal(opening.id.toString())} className="btn theme_btn btn-primary" data-bs-dismiss="modal">
//                                                     Start<svg className='svg_icons' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
//                                                         <path d="M3.4 81.7c-7.9 15.8-1.5 35 14.3 42.9L280.5 256 17.7 387.4C1.9 395.3-4.5 414.5 3.4 430.3s27.1 22.2 42.9 14.3l320-160c10.8-5.4 17.7-16.5 17.7-28.6s-6.8-23.2-17.7-28.6l-320-160c-15.8-7.9-35-1.5-42.9 14.3z" />
//                                                     </svg>
//                                                 </button>
//                                             </Link>
//                                         </div>
//                                     </div>
//                                 </div>
//                             ))}
//                         </div>
//                         <div className="all_model">
//                             <div
//                                 className="modal fade"
//                                 id="exampleModalOne"
//                                 data-bs-backdrop="static"
//                                 data-bs-keyboard="false"
//                                 aria-labelledby="exampleModalOneLabel"
//                                 aria-hidden="true">
//                                 <div className="modal-dialog">
//                                     <div className="modal-content">
//                                         <div className="modal-header">
//                                             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                                         </div>
//                                         <div className="modal-body">
//                                             <p className="text-left">Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
//                                             <p className="text-left">Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
//                                             <p className="text-left">Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
//                                             <p className="text-left">Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
//                                         </div>
//                                         <div className="modal-footer">
//                                             <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="all_model">
//                             <div
//                                 className="modal fade"
//                                 id="exampleModalTwo"
//                                 data-bs-backdrop="static"
//                                 data-bs-keyboard="false"
//                                 aria-labelledby="exampleModalTowLabel"
//                                 aria-hidden="true">
//                                 <div className="modal-dialog">
//                                     <div className="modal-content">
//                                         <div className="modal-header">
//                                             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                                         </div>
//                                         <div className="modal-body">
//                                             <p className="text-left">Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
//                                             <p className="text-left">Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
//                                       <p className="text-left">Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
//                                             <p className="text-left">Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
//                                             <p className="text-left">Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
//                                             <div className='text-left'>
//                                                 <input
//                                                     type="checkbox"
//                                                     aria-label="Checkbox for following text input"
//                                                     checked={isChecked}
//                                                     onChange={handleCheckboxChange}
//                                                 />
//                                                 <label htmlFor="" className='px-2'>Accept Term & Condition</label>
//                                             </div>
//                                         </div>
//                                         <div className="modal-footer">
//                                             <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
//                                             {isChecked ? (
//                                                 <Link to="/testQuiz" title="Test Practice!">
//                                                     <button type="button" className="btn btn-primary" data-bs-dismiss="modal">
//                                                         Start
//                                                     </button>
//                                                 </Link>
//                                             ) : (
//                                                 <span>
//                                                     <button type="button" className="btn btn-primary" disabled>
//                                                         Start
//                                                     </button>
//                                                 </span>
//                                             )}
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="mobile-warning">
//                 <p>Sorry, this website is not available on mobile devices. Please use a tablet or PC.</p>
//             </div>
//         </>
//     );
// };