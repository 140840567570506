import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import "../assets/css/tickets.css";
import userIcon from "../assets/images/icons-user.png"
import { SideBar } from './SideBar';
import videoImage from "../assets/images/defult-Video.png"
import { Link, useNavigate } from 'react-router-dom';
import { getOpeningAPI } from "../api/getQuizListAPI";
import { AutoScaling } from 'aws-sdk';
// import axios from 'axios';

export const TestDetailForCandidate: React.FC = () => {
    // const userInfo = localStorage.getItem('UserInfo');
    // const [openingData, setOpeningData] = useState<any>(null);
    const [isChecked, setIsChecked] = useState(false);
    // const [openings, setOpenings] = useState<Opening[]>([]);
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };


    return (<div className='sidebar_container'>
        {/* <SideBar /> */}
        <div className='container-fluid pt-3'>
            <div className="container">
                <h3>Thankyou for the registration <br /> Whenever ur ready start the test from below link.</h3> <br />
                {/* <div className="d-flex justify-content-between align-items-center">
                    <h3 className="theme_color">{userInfo}Test</h3>
                    <img src={userIcon} alt="{userInfo}" />
                </div> */}
                {/* <table className="table table-bordered table-info my-4">
                    <tbody>
                        <tr className='theme_Bg'>
                            <td colSpan={4} className="theme_color">
                                Hi <span className="fw-bold">{userInfo}</span>, these are the tests Gyrix Tecnolabs want you to do. First read the intractions then click on test to start.
                            </td>
                        </tr>
                    </tbody>
                </table> */}
                {/* new updated design */}
                <div className="row row-cols-1 row-cols-md-2 g-4 d-flex">


                    <div className="col">
                        <div className="card h-100">
                            <div className='d-flex justify-content-between align-items-baseline pb-3'>
                                <h6 className="card-title">Title</h6>
                            </div>
                            <img src={videoImage} alt="backgrounImage" className="card-img-top" />
                            <div className="card-body">
                                <br />
                            </div>
                            <div className="card-footer">

                                <button className="theme_btn btn" type="button" data-bs-toggle="modal" data-bs-target="#exampleModalTwo">
                                    Start Live Test
                                    <svg className='svg_icons' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                        <path d="M3.4 81.7c-7.9 15.8-1.5 35 14.3 42.9L280.5 256 17.7 387.4C1.9 395.3-4.5 414.5 3.4 430.3s27.1 22.2 42.9 14.3l320-160c10.8-5.4 17.7-16.5 17.7-28.6s-6.8-23.2-17.7-28.6l-320-160c-15.8-7.9-35-1.5-42.9 14.3z" />
                                    </svg>
                                </button>
                                &nbsp;&nbsp;&nbsp;
                                <Link to="/practiceTest" title="Test Practice!">
                                    <button className="theme_btn btn" type="button" >
                                        Pracrice Test
                                        <svg className='svg_icons' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                            <path d="M3.4 81.7c-7.9 15.8-1.5 35 14.3 42.9L280.5 256 17.7 387.4C1.9 395.3-4.5 414.5 3.4 430.3s27.1 22.2 42.9 14.3l320-160c10.8-5.4 17.7-16.5 17.7-28.6s-6.8-23.2-17.7-28.6l-320-160c-15.8-7.9-35-1.5-42.9 14.3z" />
                                        </svg>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>


                </div>

                <div className="all_model">
                    <div
                        className="modal fade"
                        id="exampleModalOne"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        aria-labelledby="exampleModalOneLabel"
                        aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    {/* <h5 className="modal-title" id="exampleModalOneLabel">Intractions</h5> */}
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <p className="text-left">Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                    <p className="text-left">Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                    <p className="text-left">Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                    <p className="text-left">Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                    <p className="text-left">Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                    <p className="text-left">Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                    <p className="text-left">Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                    <p className="text-left">Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                    <p className="text-left">Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                    <p className="text-left">Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                    <p className="text-left">Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                    <p className="text-left">Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                    <p className="text-left">Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                    <p className="text-left">Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                    <p className="text-left">Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                    <p className="text-left">Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                    <p className="text-left">Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                    <p className="text-left">Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="all_model">
                    <div
                        className="modal fade"
                        id="exampleModalTwo"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        aria-labelledby="exampleModalTowLabel"
                        aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    {/* <h5 className="modal-title" id="exampleModalTowLabel">Intractions</h5> */}
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <p className="text-left">Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                    <p className="text-left">Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                    <p className="text-left">Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                    <p className="text-left">Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                    <p className="text-left">Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                    <p className="text-left">Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                    <p className="text-left">Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                    <p className="text-left">Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                    <p className="text-left">Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                    <p className="text-left">Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                    <p className="text-left">Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                    <p className="text-left">Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                    <p className="text-left">Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                    <p className="text-left">Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                    <p className="text-left">Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                    <p className="text-left">Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                    <p className="text-left">Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                    <p className="text-left">Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>

                                    <div className='text-left'>
                                        <input
                                            type="checkbox"
                                            aria-label="Checkbox for following text input"
                                            checked={isChecked}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label htmlFor="" className='px-2'>Accept Term & Condition</label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    {isChecked ? (
                                        <Link to="/testQuiz" title="Test Practice!">
                                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">
                                                Start
                                            </button>
                                        </Link>
                                    ) : (
                                        <span>
                                            <button type="button" className="btn btn-primary" disabled>
                                                Start
                                            </button>
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div >);
};

