import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserLoginAPIs } from '../api/UserLoginAPIs';
import "./styles.css"
import { toast } from "react-toastify";
import { useRefreshUser } from "../component/context/RefreshUserContext";
import { useAuth } from "../component/context/AuthenticationContext";

export const EmailForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [prospect, setProspect] = useState('');

    const [loginFailed, setLoginFailed] = useState(false);
    // const { setRefreshUser } = useRefreshUser();
    const { updateAuthStatus } = useAuth();
    const navigate = useNavigate();

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            const response = await UserLoginAPIs(email, password);
            console.log("response", response);
            if (response.data.Token) {
                localStorage.setItem("UserInfo", response.data.Name);
                localStorage.setItem("token", response.data.Token);
                console.log("esponse.data.token", response.data.Token);
                const successMessage = 'Login successful! Redirecting to system configuration...';
                toast.success(successMessage, {
                });
                updateAuthStatus(true);
                setTimeout(() => {
                    navigate("/dashboard");
                }, 2000);
            } else {
                updateAuthStatus(false);
                const errorMessage = 'Login failed. Email or password does not match.';
                toast.error(errorMessage, {
                });
            }
        } catch (err) {
            updateAuthStatus(false);
            const errorMessage = 'Login failed. An error occurred.';
            toast.error(errorMessage, {
            });
            console.error(err);
        }
    };

    const hardcodedEmail = 'example@email.com';
    const hardcodedProspect = 'prospect123';

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (email === hardcodedEmail && prospect === hardcodedProspect) {
            //   setShowSuccessMessage(true);
            setLoginFailed(false);
            localStorage.setItem('authToken', 'mockToken');
            const successMessage = 'Login successful! Redirecting to system configuration...';
            toast.success(successMessage, {
            });
            setTimeout(() => {
                navigate("/dashboard");
            }, 2000);
        } else {
            // Add logic for unsuccessful login
            setLoginFailed(true);
            //   setShowSuccessMessage(false);
            const errorMessage = 'Login failed. Email or password does not match.';
            toast.error(errorMessage, {
            });
            console.log('Login failed. Email or prospect does not match.');
        }
    };

    const closePopup = () => {
        setLoginFailed(false);
    };

    return (<>
        <section>
            <div className="container-fluid">
                <div className="container_sign">
                    <div
                        className="row justify-content-center align-items-center"
                        style={{ height: "100vh" }}
                    >
                        <div className="col-sm-12 col-md-6 col-lg-6 form-continer">
                            <div className="start-form">
                                {/* <form onSubmit={handleLogin}> */}
                                    <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email address</label>
                                        <input
                                            id="email"
                                            type="email"
                                            placeholder="Email"
                                            className="form-control form-input text-black"
                                            name="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                            autoFocus
                                        />
                                    </div>
                                    <div className="mb-3">
                                        {/* <label htmlFor="password" className="form-label">Prospect</label>
                                        <input
                                            id="password"
                                            type="text"
                                            placeholder="Prospect"
                                            className="form-control form-input text-black"
                                            name="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                            autoFocus
                                        /> */}
                                        <label htmlFor="prospect" className="form-label">Prospect</label>
                                        <input
                                            id="prospect"
                                            type="text"
                                            placeholder="prospect"
                                            className="form-control form-input text-black"
                                            name="prospect"
                                            value={prospect}
                                            onChange={(e) => setProspect(e.target.value)}
                                            required
                                            autoFocus
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <button
                                            type="submit"
                                            className="btn btn-primary mb-3" title="Continue">Continue</button>
                                    </div>
                                    {/* <div className="text-center">
                                        <h6 className="">Don't have an account? <Link to="/signup" title="Sign up here!">Sign up here!</Link></h6>
                                    </div> */}
                                </form>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 right_section">
                            <div className="form-right-img">
                                {/* <img src="https://storage.googleapis.com/yoodli-public/onboarding-assets/presentation1.png" alt="" className="img-fluid" /> */}
                                <img src="https://vidcruiter.com/_astro/what-is-video-interviewing-hero_1rNGwD.avif" alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>);
}

