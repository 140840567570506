import axios from "axios";
import { UserFromEmail } from "./endpoints";

//export async function UserSignupAPIs(
export async function CreateUserApis(
    email: string,
    name: string,
    tenant_id: string,
    opening_id: string,
    // email: string,
    // password: string,
    // collegeName: string
): Promise<any> {
    const payload = {
        email,
        name,
        opening_id,
        tenant_id

        // email,
        // password,
        // collegeName,
    };
    const headers = {
        'Content-Type': 'application/json',
    };
    try {
        const response = await axios.post(UserFromEmail, payload,
            {
                headers
            });
        console.error("response signup:", response);
        localStorage.setItem('authToken', 'mockToken');
        return response.data;
    } catch (error) {
        console.error("Error during user signup:", error);
        throw error;
    }
}
