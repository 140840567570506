// import React, { useState, useEffect, useCallback } from 'react';
// import axios from 'axios';
// import { Card, Button, Modal } from 'react-bootstrap';
// import { useSearchParams } from 'react-router-dom';
// import Webcam from 'react-webcam';
// import "./styles.css";
// import { useNavigate } from 'react-router-dom';
// import CameraComponent from './CameraComponent';

// const API_URL = 'https://avaapi.flair-solution.com/api/practice/questions/list';

// interface Question {
//     wait_time_in_sec: number;
//     answer_time_in_sec: number;
//     video_link: string;
//     title: string;
// }

// export const DuplicatePracticeQuiz: React.FC = () => {
//     const [searchParams] = useSearchParams();
//     const openingUserId = searchParams.get("opening_users_id");
//     const [showWaitTimer, setShowWaitTimer] = useState(false);
//     const [waitTimerSeconds, setWaitTimerSeconds] = useState(15);
//     const [answerTimerSeconds, setAnswerTimerSeconds] = useState(0);
//     const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
//     const [questions, setQuestions] = useState<Question[]>([]);
//     const [showWebcam, setShowWebcam] = useState(false);
//     const [showThankYou, setShowThankYou] = useState(false);
//     const [cameraAccess, setCameraAccess] = useState<boolean | null>(null);
//     const navigate = useNavigate();

//     const handleCloseThankYou = useCallback(() => {
//         setShowThankYou(false);
//         navigate('/allopenings');
//     }, [navigate]);

//     const handleNextQuestion = useCallback(() => {
//         if (currentQuestionIndex < questions.length - 1) {
//             const nextQuestion = questions[currentQuestionIndex + 1];
//             setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
//             setWaitTimerSeconds(nextQuestion?.wait_time_in_sec || 15);
//             setAnswerTimerSeconds(nextQuestion?.answer_time_in_sec || 0);
//             setShowWebcam(false);
//             setShowWaitTimer(false);
//         } else {
//             setShowThankYou(true);
//         }
//     }, [currentQuestionIndex, questions]);

//     const checkCameraAccess = useCallback(async () => {
//         try {
//             const stream = await navigator.mediaDevices.getUserMedia({ video: true });
//             setCameraAccess(true);
//             stream.getTracks().forEach(track => track.stop());
//         } catch (error) {
//             setCameraAccess(false);
//         }
//     }, []);

//     useEffect(() => {
//         if (openingUserId) {
//             localStorage.setItem('opening_users_id', openingUserId);
//         }
//     }, [openingUserId]);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await axios.get(API_URL);
//                 if (response.data.success) {
//                     const fetchedQuestions = response.data.data as Question[];
//                     setQuestions(fetchedQuestions);
//                     if (fetchedQuestions.length > 0) {
//                         setWaitTimerSeconds(fetchedQuestions[0].wait_time_in_sec);
//                         setAnswerTimerSeconds(fetchedQuestions[0].answer_time_in_sec);
//                     }
//                 } else {
//                     console.error(response.data.message || 'Unknown error');
//                 }
//             } catch (error) {
//                 console.error('Error while fetching data from API:', error);
//             }
//         };

//         fetchData();
//     }, []);

//     useEffect(() => {
//         if (showWaitTimer && waitTimerSeconds > 0) {
//             const waitInterval = setInterval(() => {
//                 setWaitTimerSeconds((prev) => prev - 1);
//             }, 1000);
//             return () => clearInterval(waitInterval);
//         }
//     }, [showWaitTimer, waitTimerSeconds]);

//     useEffect(() => {
//         if (showWebcam && answerTimerSeconds > 0) {
//             const answerInterval = setInterval(() => {
//                 setAnswerTimerSeconds((prev) => prev - 1);
//             }, 1000);
//             return () => clearInterval(answerInterval);
//         }

//         if (showWebcam && answerTimerSeconds === 0) {
//             setShowWebcam(false);
//             handleNextQuestion();
//         }
//     }, [showWebcam, answerTimerSeconds, handleNextQuestion]);

//     useEffect(() => {
//         if (!showWebcam && waitTimerSeconds === 0) {
//             setShowWebcam(true);
//         }
//     }, [showWebcam, waitTimerSeconds]);

//     useEffect(() => {
//         checkCameraAccess();
//         const interval = setInterval(checkCameraAccess, 5000);
//         return () => clearInterval(interval);
//     }, [checkCameraAccess]);

//     if (cameraAccess === null) {
//         return <div>Loading...</div>;
//     }

//     if (cameraAccess === false) {
//         return (
//             <div className="enablecamera">
//                 <div className='box-above-background'>
//                     <div>
//                         <img src="https://d34hmiuaex7c0.cloudfront.net/5159/uploads/page_featured_img_1721389891485.PNG" width={"350px"} />
//                     </div>
//                     <p className='camera_error'>Camera access is required to view this content. Please enable camera permissions and try again.</p>
//                     <CameraComponent />
//                 </div>
//             </div>
//         );
//     }

//     const handleStartRecordingClick = () => {
//         setShowWaitTimer(true);
//     };

//     return (
//         <div>
//             <section className="d-flex justify-content-center m-auto">
//                 {!showWebcam && (
//                     <Card className="card">
//                         <div className="container clock_center">
//                             <div className="row clock_center">
//                                 <div className="col-md-12 col-sm-12">
//                                     <h4 className="kanit-medium" style={{ textAlign: 'center' }}>Question {currentQuestionIndex + 1}</h4>
//                                 </div>
//                                 <div className="col-md-10 col-sm-12 video-player">
//                                     <div className="container py-3">
//                                         <div className="camera-width-con">
//                                             {showWaitTimer && !showWebcam && (
//                                                 <div className="wait-timer">
//                                                     <img className="watch_image" src="https://d34hmiuaex7c0.cloudfront.net/5159/uploads/page_featured_img_1721384221994.png" />
//                                                     <h2 className='Wait_timer_clock'>{waitTimerSeconds}</h2>
//                                                     <Button className="center-button" onClick={() => setShowWebcam(true)}>
//                                                         Show Webcam
//                                                     </Button>
//                                                 </div>
//                                             )}
//                                             {!showWaitTimer && !showWebcam && (
//                                                 <div>
//                                                     <video
//                                                         src={questions[currentQuestionIndex]?.video_link}
//                                                         autoPlay
//                                                         onEnded={handleStartRecordingClick}
//                                                     />
//                                                     <div className="button_box">
//                                                         <Button className="center-button" onClick={handleStartRecordingClick}>
//                                                             Start recording
//                                                         </Button>
//                                                     </div>
//                                                 </div>
//                                             )}
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </Card>
//                 )}
//             </section>

//             {showWebcam && (
//                 <section className="d-flex justify-content-center m-auto mt-4">
//                     <Card>
//                         <div className="container">
//                             <div className="row">
//                                 <div className="col-md-12 col-sm-12">
//                                     <h4 className="question-page" style={{ textAlign: 'center' }}>Question {currentQuestionIndex + 1} :- {questions[currentQuestionIndex]?.title}</h4>
//                                 </div>
//                                 <div className="col-md-8 col-sm-12 video-player">
//                                     <div className="container py-3">
//                                         <div className="camera-width-con">
//                                             <Webcam />
//                                             <h2 className='answer_time' style={{ textAlign: 'center' }}>Answer Time: {answerTimerSeconds}</h2>
//                                             <br />
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <Card.Footer>
//                             <div className="text-center mt-5">
//                                 <Button className="center-button" onClick={() => {
//                                     if (questions.length > 0 && currentQuestionIndex === questions.length - 1) {
//                                         setShowThankYou(true);
//                                     } else {
//                                         handleNextQuestion();
//                                     }
//                                 }}>
//                                     {questions.length > 0 && currentQuestionIndex === questions.length - 1 ? 'Finish Test' : 'Next Question'}
//                                 </Button>
//                             </div>
//                         </Card.Footer>
//                     </Card>
//                 </section>
//             )}

//             {questions.length > 0 && currentQuestionIndex === questions.length - 1 && (
//                 <Modal className='go_home_modal' show={showThankYou} onHide={handleCloseThankYou}>
//                     <Modal.Body>
//                         <div className='thankyouimg_box'>
//                             <img className='thankyou_image' width={'450px'} src='https://d34hmiuaex7c0.cloudfront.net/5159/uploads/page_featured_img_1721637242269.png' />
//                         </div>
//                         <h1 className='text-center'>Thank You for Completing the Quiz!</h1>
//                         <div className='social_icons'>
//                             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z" /></svg>
//                             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>
//                             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" /></svg>
//                             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M204 6.5C101.4 6.5 0 74.9 0 185.6 0 256 39.6 296 63.6 296c9.9 0 15.6-27.6 15.6-35.4 0-9.3-23.7-29.1-23.7-67.8 0-80.4 61.2-137.4 140.4-137.4 68.1 0 118.5 38.7 118.5 109.8 0 53.1-21.3 152.7-90.3 152.7-24.9 0-46.2-18-46.2-43.8 0-37.8 26.4-74.4 26.4-113.4 0-66.2-93.9-54.2-93.9 25.8 0 16.8 2.1 35.4 9.6 50.7-13.8 59.4-42 147.9-42 209.1 0 18.9 2.7 37.5 4.5 56.4 3.4 3.8 1.7 3.4 6.9 1.5 50.4-69 48.6-82.5 71.4-172.8 12.3 23.4 44.1 36 69.3 36 106.2 0 153.9-103.5 153.9-196.8C384 71.3 298.2 6.5 204 6.5z" /></svg>
//                         </div>
//                         <a className='modal_button_center' href="/allopenings">
//                             <Button className="center-button" onClick={handleCloseThankYou}>Return to Home</Button>
//                         </a>
//                     </Modal.Body>
//                 </Modal>
//             )}
//         </div>
//     );
// };


import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Card, Button, Modal } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import Webcam from 'react-webcam';
import "./styles.css";
import { useNavigate } from 'react-router-dom';
import CameraComponent from './CameraComponent';

const API_URL = 'https://avaapi.flair-solution.com/api/practice/questions/list';

interface Question {
    wait_time_in_sec: number;
    answer_time_in_sec: number;
    video_link: string;
    title: string;
}

export const DuplicatePracticeQuiz: React.FC = () => {
    const [searchParams] = useSearchParams();
    const openingUserId = searchParams.get("opening_users_id");
    const [showWaitTimer, setShowWaitTimer] = useState(false);
    const [waitTimerSeconds, setWaitTimerSeconds] = useState(15);
    const [answerTimerSeconds, setAnswerTimerSeconds] = useState(0);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [questions, setQuestions] = useState<Question[]>([]);
    const [showWebcam, setShowWebcam] = useState(false);
    const [showThankYou, setShowThankYou] = useState(false);
    const [cameraAccess, setCameraAccess] = useState<boolean | null>(null);
    const [videoPlayed, setVideoPlayed] = useState(false); // New state variable
    const navigate = useNavigate();

    const handleCloseThankYou = useCallback(() => {
        setShowThankYou(false);
        navigate('/allopenings');
    }, [navigate]);

    const handleNextQuestion = useCallback(() => {
        if (currentQuestionIndex < questions.length - 1) {
            const nextQuestion = questions[currentQuestionIndex + 1];
            setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
            setWaitTimerSeconds(nextQuestion?.wait_time_in_sec || 15);
            setAnswerTimerSeconds(nextQuestion?.answer_time_in_sec || 0);
            setShowWebcam(false);
            setShowWaitTimer(false);
        } else {
            setShowThankYou(true);
        }
    }, [currentQuestionIndex, questions]);

    const checkCameraAccess = useCallback(async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            setCameraAccess(true);
            stream.getTracks().forEach(track => track.stop());
        } catch (error) {
            setCameraAccess(false);
        }
    }, []);

    useEffect(() => {
        if (openingUserId) {
            localStorage.setItem('opening_users_id', openingUserId);
        }
    }, [openingUserId]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(API_URL);
                if (response.data.success) {
                    const fetchedQuestions = response.data.data as Question[];
                    setQuestions(fetchedQuestions);
                    if (fetchedQuestions.length > 0) {
                        setWaitTimerSeconds(fetchedQuestions[0].wait_time_in_sec);
                        setAnswerTimerSeconds(fetchedQuestions[0].answer_time_in_sec);
                    }
                } else {
                    console.error(response.data.message || 'Unknown error');
                }
            } catch (error) {
                console.error('Error while fetching data from API:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (showWaitTimer && waitTimerSeconds > 0) {
            const waitInterval = setInterval(() => {
                setWaitTimerSeconds((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(waitInterval);
        }
    }, [showWaitTimer, waitTimerSeconds]);

    useEffect(() => {
        if (showWebcam && answerTimerSeconds > 0) {
            const answerInterval = setInterval(() => {
                setAnswerTimerSeconds((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(answerInterval);
        }

        if (showWebcam && answerTimerSeconds === 0) {
            setShowWebcam(false);
            handleNextQuestion();
        }
    }, [showWebcam, answerTimerSeconds, handleNextQuestion]);

    useEffect(() => {
        if (!showWebcam && waitTimerSeconds === 0) {
            setShowWebcam(true);
        }
    }, [showWebcam, waitTimerSeconds]);

    useEffect(() => {
        checkCameraAccess();
        const interval = setInterval(checkCameraAccess, 5000);
        return () => clearInterval(interval);
    }, [checkCameraAccess]);

    if (cameraAccess === null) {
        return <div>Loading...</div>;
    }

    if (cameraAccess === false) {
        return (
            <div className="enablecamera">
                <div className='box-above-background'>
                    <div>
                        <img src="https://d34hmiuaex7c0.cloudfront.net/5159/uploads/page_featured_img_1721389891485.PNG" width={"350px"} />
                    </div>
                    <p className='camera_error'>Camera access is required to view this content. Please enable camera permissions and try again.</p>
                    <CameraComponent />
                </div>
            </div>
        );
    }

    const handleStartRecordingClick = () => {
        setShowWaitTimer(true);
        setVideoPlayed(false);
    };

    const handleVideoEnded = () => {
        setVideoPlayed(true); // Set videoPlayed to true when the video ends
    };

    return (
        <div>
            <section className="d-flex justify-content-center m-auto">
                {!showWebcam && (
                    <Card className="card">
                        <div className="container clock_center">
                            <div className="row clock_center">
                                <div className="col-md-12 col-sm-12">
                                    <h4 className="kanit-medium" style={{ textAlign: 'center' }}>Question {currentQuestionIndex + 1}</h4>
                                </div>
                                <div className="col-md-10 col-sm-12 video-player">
                                    <div className="container py-3">
                                        <div className="camera-width-con">
                                            {showWaitTimer && !showWebcam && (
                                                <div className="wait-timer">
                                                    <img className="watch_image" src="https://d34hmiuaex7c0.cloudfront.net/5159/uploads/page_featured_img_1721384221994.png" />
                                                    <h2 className='Wait_timer_clock'>{waitTimerSeconds}</h2>
                                                    <Button className="center-button" onClick={() => setShowWebcam(true)}>
                                                        Show Webcam
                                                    </Button>
                                                </div>
                                            )}
                                            {!showWaitTimer && !showWebcam && (
                                                <div>
                                                    <video
                                                        src={questions[currentQuestionIndex]?.video_link}
                                                        autoPlay
                                                        onEnded={handleVideoEnded} // Use handleVideoEnded
                                                    />
                                                    {videoPlayed && ( // Conditionally render button based on videoPlayed
                                                        <div className="button_box">
                                                            <Button className="center-button" onClick={handleStartRecordingClick}>
                                                                Start recording
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                )}
            </section>

            {showWebcam && (
                <section className="d-flex justify-content-center m-auto mt-4">
                    <Card>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <h4 className="question-page" style={{ textAlign: 'center' }}>Question {currentQuestionIndex + 1} :- {questions[currentQuestionIndex]?.title}</h4>
                                </div>
                                <div className="col-md-8 col-sm-12 video-player">
                                    <div className="container py-3">
                                        <div className="camera-width-con">
                                            <Webcam />
                                            <h2 className='answer_time' style={{ textAlign: 'center' }}>Answer Time: {answerTimerSeconds}</h2>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Card.Footer>
                            <div className="text-center mt-5">
                                <Button className="center-button" onClick={() => {
                                    if (questions.length > 0 && currentQuestionIndex === questions.length - 1) {
                                        setShowThankYou(true);
                                    } else {
                                        handleNextQuestion();
                                    }
                                }}>
                                    {questions.length > 0 && currentQuestionIndex === questions.length - 1 ? 'Finish Test' : 'Next Question'}
                                </Button>
                            </div>
                        </Card.Footer>
                    </Card>
                </section>
            )}

            {questions.length > 0 && currentQuestionIndex === questions.length - 1 && (
                <Modal className='go_home_modal' show={showThankYou} onHide={handleCloseThankYou}>
                    <Modal.Body>
                        <div className='thankyouimg_box'>
                            <img className='thankyou_image' width={'450px'} src='https://d34hmiuaex7c0.cloudfront.net/5159/uploads/page_featured_img_1721637242269.png' />
                        </div>
                        <h1 className='text-center'>Thank You for Completing the Quiz!</h1>
                        <div className='social_icons'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z" /></svg>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" /></svg>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M204 6.5C101.4 6.5 0 74.9 0 185.6 0 256 39.6 296 63.6 296c9.9 0 15.6-27.6 15.6-35.4 0-9.3-23.7-29.1-23.7-67.8 0-80.4 61.2-137.4 140.4-137.4 68.1 0 118.5 38.7 118.5 109.8 0 53.1-21.3 152.7-90.3 152.7-24.9 0-46.2-18-46.2-43.8 0-37.8 26.4-74.4 26.4-113.4 0-66.2-93.9-54.2-93.9 25.8 0 16.8 2.1 35.4 9.6 50.7-13.8 59.4-42 147.9-42 209.1 0 18.9 2.7 37.5 4.5 56.4 3.4 3.8 1.7 3.4 6.9 1.5 50.4-69 48.6-82.5 71.4-172.8 12.3 23.4 44.1 36 69.3 36 106.2 0 153.9-103.5 153.9-196.8C384 71.3 298.2 6.5 204 6.5z" /></svg>
                        </div>
                        <a className='modal_button_center' href="/allopenings">
                            <Button className="center-button" onClick={handleCloseThankYou}>Return to Home</Button>
                        </a>
                    </Modal.Body>
                </Modal>
            )}
        </div>
    );
};
