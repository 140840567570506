import React, { useState, useEffect, useRef, useCallback } from 'react';
import AWS from 'aws-sdk';
import axios from 'axios';
import { useReactMediaRecorder } from 'react-media-recorder';
import { Card, Button, Spinner } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import videoImg from '../assets/images/defult-Video.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link, useNavigate } from 'react-router-dom';
import videoImage from "../assets/images/defult-Video.png"
import { faFlag, faInfo } from '@fortawesome/free-solid-svg-icons';
// import { SideBar } from './SideBar';
import Webcam from "react-webcam";

import { config } from './config';
import { LiveQuizComponent } from './LiveQuizComponent';

interface QuizQuestion {
    question: string;
    videoURL: string;
    waitTime: number;
    answerTime: number;
    questionId: number;
}

export const LiveTestDuplicatePage: React.FC = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const openingUserId = searchParams.get("opening_users_id");

    const [isChecked, setIsChecked] = useState(false);


    const Ref = useRef(null);
    const isStoppingRef = useRef(false);
    // The state for our timer
    const [timer, setTimer] = useState("00:00:00");

    const [waitTimerSeconds, setWaitTimerSeconds] = useState(0);
    const [answerTimerSeconds, setAnswerTimerSeconds] = useState(0);


    const [isSectionVisible, setSectionVisible] = useState<boolean>(true);

    const [showScore, setShowSuccessScore] = useState(false);
    const [questions, setQuestions] = useState<QuizQuestion[]>([]);
    const webcamRef = useRef<any>(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [questionSubmitted, setQuestionSubmittedFlag] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state
    const [blobDataUrl, setBlobDataUrl] = useState(""); // Loading state
    const [response, setResponse] = useState(null);
    const [isThankYouBoxVisible, setIsThankYouBoxVisible] = useState(true);
    const navigate = useNavigate();

    let blobUrlData: string | undefined = "";

    let stopCounter = 0;

    const handleStop = useCallback(async (blobUrl: string | undefined) => {
        if (isStoppingRef.current) {
            console.log("status:-", status);
            console.log("blobUrl:-", blobUrl); // This should log the blob URL
            blobUrlData = blobUrl;
            // You can handle your submit logic here
            isStoppingRef.current = false;
        }

    }, []);

    const { error: mediaError, status, startRecording, stopRecording, mediaBlobUrl } = useReactMediaRecorder({
        audio: true,
        video: true,
        // blobPropertyBag: { type: 'video/webm' },
        onStop: async (blobUrl) => {
            console.log("stopCounter:----", stopCounter);
            setBlobDataUrl(blobUrl);
            let uploadedResult = await uploadToS3(blobUrl);

        }
    });



    const [isLiveQuizVisible, setIsLiveQuizVisible] = useState(false);

    // Function to handle the button click
    const handleButtonClick = () => {
        setIsLiveQuizVisible(true);
        setIsThankYouBoxVisible(false);
    };




    useEffect(() => {
        const fetchData = async () => {
            try {
                const opening_users_id = localStorage.getItem('opening_users_id');
                const token = localStorage.getItem('token');
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                };
                const response = await axios.post(
                    'https://avaapi.flair-solution.com/api/questions/list',
                    { opening_users_id },
                    { headers }
                );
                if (response.data.success) {
                    const apiQuestions: QuizQuestion[] = response.data.data.map((question: any) => ({
                        question: question.title,
                        videoURL: question.video_link,
                        waitTime: question.wait_time_in_sec,
                        answerTime: question.answer_time_in_sec,
                        questionId: question.question_id
                    }));
                    setQuestions(apiQuestions);

                } else {
                    console.error(response.data.message || 'Unknown error');
                }
            } catch (error) {
                console.error('Error while fetching data from API:', error);
            }
        };

        fetchData();
    }, []);

    const handleNextClick = async () => {
        // setCurrentQuestionIndex((prevIndex: number) => Math.min(prevIndex + 1, questions.length - 1));
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const formatQuestionNumber = (index: number) => `Q${index + 1}`;



    const startRecordingAnswer = async () => {
        setWaitTimerSeconds(0);
        startRecording();
        if (questions && questions[currentQuestionIndex] && questions[currentQuestionIndex].answerTime) {
            setAnswerTimerSeconds(questions[currentQuestionIndex].answerTime)
        }
    }

    const stopRecordingAnsAndSubmit = async () => {
        isStoppingRef.current = true;
        setAnswerTimerSeconds(0);

        stopRecording();

        console.log("stopRecordingAnsAndSubmit Camera Status:-", status);
    }

    const uploadToS3 = async (blobUrl: string | undefined) => {
        setLoading(true);
        const region = 'ap-south-1';

        if (!blobUrl) {
            console.error('Media blob URL is not available');
            setLoading(false); // Add this line to handle loading state
            return;
        }
        let resultBlob = await fetchData(blobUrl);

        const tenantId = localStorage.getItem('tenant_id');
        const applicantId = localStorage.getItem('opening_users_id');
        let payload: any = {
            tenant_id: tenantId,
            application_id: applicantId
        };
        let fileName = `${tenantId}_${applicantId}_${questions[currentQuestionIndex]?.questionId}_${new Date().getTime()}`;

        console.log("resultBlob:-", resultBlob);

        const blob: any = new Blob([resultBlob || ""], { type: 'video/webm' }); // Example Blob
        const file = new File([blob], `${fileName}.webm`, { type: 'video/webm' });

        AWS.config.update({
            accessKeyId: config.accessKeyId,//AWS_ACCESS_KEY_ID,
            secretAccessKey: config.secretAccessKey,//AWS_SECRET_ACCESS_KEY,
        });
        const s3 = new AWS.S3({
            params: { Bucket: "ava-edusimplified-public" },
            region: region,
        });

        const params = {
            Bucket: "ava-edusimplified-public",
            Key: `${fileName}.webm`,
            Body: file,
            ContentType: 'video/webm'
        };


        try {
            let result = await s3.upload(params).promise();
            if (result.Key) {
                console.log("S3 Result", result);
                setLoading(false); // Hide loader after upload
                setQuestionSubmittedFlag(true);
                await handleNextClick();
                console.log("S3 currentQuestionIndex", currentQuestionIndex);

                payload["answer_link"] = result.Key;
                // alert('Upload successful!');
                const responseResult = await submitAnswerLinkToAPI(payload);
                if (!responseResult) {
                    console.error("Answer not uploaded, something went wrong!");
                }
                if (currentQuestionIndex === questions.length - 1) {
                    setShowSuccessScore(true);
                    localStorage.removeItem('opening_users_id');
                    localStorage.removeItem('tenant_id');
                    localStorage.removeItem('applicant_id');
                }
                return true;
            }
        } catch (error: any) {
            console.error("error", error);
            // alert('Upload failed: ' + error.message);
        } finally {
            // setShowSuccessScore(true);
        }
    }

    const fetchData = async (blobUrl: string) => {
        try {
            console.log("fetchData blobUrl:-", blobUrl);

            const response = await axios.get(blobUrl || "", { responseType: 'blob' });
            const blobDataResult = response.data;
            if (blobDataResult) {
                return blobDataResult;
            }
            return "";
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        if (questions[currentQuestionIndex]) {
            setWaitTimerSeconds(questions[currentQuestionIndex].waitTime);

        }

        // if (waitTimerSeconds === 0 && status == 'recording') {
        //   setAnswerTimerSeconds(questions[currentQuestionIndex].answerTime);
        // }
    }, [currentQuestionIndex, questions]);

    useEffect(() => {
        const waitInterval = setInterval(() => {
            setWaitTimerSeconds((prev) => (prev > 0) ? prev - 1 : 0);
        }, 1000);

        const startRecordAfterTime = async () => {
            clearInterval(waitInterval);
            // Handle what to do when wait time is over
            await startRecordingAnswer();
        }

        if (waitTimerSeconds === 0) {
            startRecordAfterTime();
        }

        return () => clearInterval(waitInterval);
    }, [waitTimerSeconds]);

    useEffect(() => {
        const answerInterval = setInterval(() => {
            setAnswerTimerSeconds((prev) => (prev > 0) ? prev - 1 : 0);
        }, 1000);

        const submitAnswerAfterTime = async () => {
            clearInterval(answerInterval);
            // Handle what to do when answer time is over
            await stopRecordingAnsAndSubmit();
        }
        if (answerTimerSeconds === 0) {
            submitAnswerAfterTime();
        }

        return () => clearInterval(answerInterval);
    }, [answerTimerSeconds]);



    const closePopup = () => {
        stopRecording();
        setShowSuccessScore(false);
        navigate('/allopenings');
    };

    const submitAnswerLinkToAPI = async (payload: any) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post('https://avaapi.flair-solution.com/api/save/answers', payload, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response?.data?.success) {
                return response.data;
            }
            // setResponse(response.data);
        }
        catch (error: any) {
            console.error(error);
            return null;
        }
    }

    return (
        <div className="container mt-4">
            {loading && ( // Show loader if loading state is true
                <div className="overlay">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            )}




            <>
                {isThankYouBoxVisible && (
                    <div className='container-fluid pt-3' id="thankyou-box">
                        <div className="container">
                            <h3 className='text-center'>Thankyou for the registration <br /> Whenever ur ready start the test from below link.</h3> <br />

                            {/* new updated design */}
                            <div className="row row-cols-1 row-cols-md-2 g-4 d-flex" >


                                <div className="col">
                                    <div className="card h-100">
                                        <div className='d-flex justify-content-between align-items-baseline pb-3'>
                                            <h6 className="card-title">Title</h6>
                                        </div>
                                        <img src={videoImage} alt="backgrounImage" className="card-img-top" />
                                        <div className="card-body">
                                            <br />
                                        </div>
                                        <div className="card-footer">

                                            <button className="theme_btn btn" type="button" data-bs-toggle="modal" data-bs-target="#exampleModalTwo">
                                                Start Live Test
                                                <svg className='svg_icons' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                    <path d="M3.4 81.7c-7.9 15.8-1.5 35 14.3 42.9L280.5 256 17.7 387.4C1.9 395.3-4.5 414.5 3.4 430.3s27.1 22.2 42.9 14.3l320-160c10.8-5.4 17.7-16.5 17.7-28.6s-6.8-23.2-17.7-28.6l-320-160c-15.8-7.9-35-1.5-42.9 14.3z" />
                                                </svg>
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                            <Link to="/demopracticetest" title="Test Practice!">
                                                <button className="theme_btn btn" type="button" >
                                                    Pracrice Test
                                                    <svg className='svg_icons' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                        <path d="M3.4 81.7c-7.9 15.8-1.5 35 14.3 42.9L280.5 256 17.7 387.4C1.9 395.3-4.5 414.5 3.4 430.3s27.1 22.2 42.9 14.3l320-160c10.8-5.4 17.7-16.5 17.7-28.6s-6.8-23.2-17.7-28.6l-320-160c-15.8-7.9-35-1.5-42.9 14.3z" />
                                                    </svg>
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>


                            </div>




                            <div className="all_model">
                                <div
                                    className="modal fade"
                                    id="exampleModalTwo"
                                    data-bs-backdrop="static"
                                    data-bs-keyboard="false"
                                    aria-labelledby="exampleModalTowLabel"
                                    aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                {/* <h5 className="modal-title" id="exampleModalTowLabel">Intractions</h5> */}
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                                <p className="text-left">Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                                <p className="text-left">Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                                <p className="text-left">Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                                <p className="text-left">Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                                <p className="text-left">Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                                <p className="text-left">Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                                <p className="text-left">Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                                <p className="text-left">Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                                <p className="text-left">Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                                <p className="text-left">Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                                <p className="text-left">Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                                <p className="text-left">Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                                <p className="text-left">Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                                <p className="text-left">Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                                <p className="text-left">Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                                <p className="text-left">Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                                <p className="text-left">Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                                <p className="text-left">Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>

                                                <div className='text-left'>
                                                    <input
                                                        type="checkbox"
                                                        aria-label="Checkbox for following text input"
                                                        checked={isChecked}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label htmlFor="" className='px-2'>Accept Term & Condition</label>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                {isChecked ? (
                                                    // <Link to="/" title="Test Practice!">
                                                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleButtonClick}>
                                                        Start
                                                    </button>
                                                    // </Link>
                                                ) : (
                                                    <span>
                                                        <button type="button" className="btn btn-primary" disabled>
                                                            Start
                                                        </button>
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>





            <>
                {isLiveQuizVisible && (

                    <section className="d-flex content-justify-center m-auto" id="livequizcomp" style={{ display: isSectionVisible ? 'block' : 'none' }}>
                        {/* <LiveQuizComponent /> */}
                        <Card>
                            <div className="container">
                                <div className="row">
                                    <>
                                        {
                                            status === "recording" && loading === false ? (
                                                <h1 style={{ textAlign: 'center' }}>Start Answers</h1>
                                            ) :
                                                (<h1 style={{ textAlign: 'center' }}>Start Questions</h1>)
                                        }
                                    </>

                                    <div className="col-md-6 col-sm-6">
                                        {
                                            <>
                                                {
                                                    status === "recording" && waitTimerSeconds === 0 && loading === false ?
                                                        (
                                                            <div><h1 style={{ textAlign: 'center' }}>Answer Time: {answerTimerSeconds}</h1></div>
                                                        ) :
                                                        (
                                                            <div><h1 style={{ textAlign: 'center' }}>Wait Time: {waitTimerSeconds}</h1></div>
                                                        )
                                                }
                                            </>
                                        }
                                        <Card.Body>
                                            <Card.Title>{formatQuestionNumber(currentQuestionIndex)}: {questions[currentQuestionIndex]?.question}</Card.Title>
                                        </Card.Body>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="container py-3">
                                            <div className="camera-width-con">
                                                {
                                                    status === 'recording' && waitTimerSeconds === 0 && loading === false ?
                                                        (
                                                            <Webcam height={600} width={600} ref={webcamRef} />
                                                        ) :
                                                        (
                                                            questionSubmitted && loading === true ?
                                                                (
                                                                    <img src={videoImg} />
                                                                ) :
                                                                (loading === false && (
                                                                    <video src={questions[currentQuestionIndex]?.videoURL}
                                                                        controls
                                                                        autoPlay={true}
                                                                    />
                                                                ))
                                                        )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Card.Footer>
                                <div className="text-center mt-3">
                                    <Button onClick={status === "recording" && waitTimerSeconds === 0 && loading === false ? stopRecordingAnsAndSubmit : startRecordingAnswer}>
                                        {status === "recording" && waitTimerSeconds === 0 && loading === false ? 'Stop Recording & Submit' : 'Start Recording'}
                                    </Button>
                                </div>
                            </Card.Footer>
                        </Card>
                    </section>
                )}
            </>













            <div className="row">
                <div className="col-md-12 col-sm-12 text-center">
                    {showScore && (
                        <div className="overlay">
                            <div className="popup" style={{ width: "450px" }}>
                                <div className="text-success py-3">
                                    <p className='text-left'> Congratulations! Your quiz has been successfully submitted.
                                        Thank you for participating and testing your knowledge.
                                        Results will be available shortly. Good luck! </p>
                                </div>
                                <div className="text-danger py-3">
                                </div>
                                <button
                                    className="btn btn-primary py-2"
                                    onClick={closePopup}
                                >Close</button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};


