import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import { Link, useNavigate } from 'react-router-dom';
import "./styles.css";
import { toast } from "react-toastify";
// import { UserSignupAPIs } from './../api/UserSignupAPIs';
import { CreateUserApis } from '../api/CreateUserApis';
import { SideBar } from './SideBar';

export default function UserFromEmail() {
    const [email, setEmail] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [opening_id, setOpeningId] = useState<string>("");
    const [tenant_id, setTenantId] = useState<string>("");
    const [shortDescription, setShortDescription] = useState('');
    const [description, setDescription] = useState('');
    const [internalTitle, setInternalTitle] = useState('');


    // const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    //     e.preventDefault();
    //     try {
    //         console.log(email, name, tenant_id, opening_id)
    //         const result = await CreateUserApis(email, name, tenant_id, opening_id);
    //         console.log('Signup successful:', result);
    //         const { opening_users_id, token } = result;

    //         // Save to local storage
    //         localStorage.setItem('opening_users_id', opening_users_id);
    //         localStorage.setItem('token', token);
    //         // const successMessage = 'Sign up successful!...';
    //         // toast.success(successMessage, {
    //         // });
    //     } catch (error) {
    //         console.error('Error during signup:', error);
    //         console.log(email, name)
    //         const errorMessage = 'Sign up failed...';
    //         toast.error(errorMessage, {
    //         });
    //     }
    // };


    const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            console.log(email, name, tenant_id, opening_id)
            const result = await CreateUserApis(email, name, tenant_id, opening_id);
            console.log('Signup successful:', result);
            const { opening_users_id, token, message } = result;
            const { id } = result.data;

            // Save to local storage
            localStorage.setItem('opening_users_id', opening_users_id);
            localStorage.setItem('token', token);
            localStorage.setItem('applicant_id', id);
            localStorage.setItem('tenant_id', tenant_id);

            // Show success message if CreateUserApis succeeded
            if (result.success) {
                // const successMessage = 'Sign up successful!...';
                toast.success(message, {});
                // window.location.href = '/livequiz'
            }

        } catch (error) {
            console.error('Error during signup:', error);
            console.log(email, name)
            const errorMessage = 'Sign up failed...';
            // toast.error(errorMessage, {});
        }
    };

    //axios
    useEffect(() => {
        // Fetch data from API
        axios.get('https://avaapi.flair-solution.com/api/opening/detail/1')
            .then(response => {
                const data = response.data.data; // Accessing the data object within the response
                // Assuming the response contains tenant_id and id (as opening_id) fields
                setTenantId(data.tenant_id.toString());
                setOpeningId(data.id.toString());
                setShortDescription(data.short_description);
                setDescription(data.description);
                setInternalTitle(data.internal_title);

            })
            .catch(error => {
                console.error('Error fetching data:', error);

            });
    }, []); // Empty dependency array to run once on component mount



    const handleTenantIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTenantId(e.target.value);
    };

    const handleOpeningIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOpeningId(e.target.value);
    };



    return (
        <>
            <section>
                <div className="row">
                    {/* <div className="col-2"><SideBar /></div> */}

                    <div className="container-fluid col-10">
                        <div className="container_sign">
                            <div
                                className="row justify-content-center align-items-center"
                                style={{ height: "100vh" }}
                            >


                                <div className="col-sm-12 col-md-6 col-lg-6 form-continer">
                                    <div className="start-form">
                                        <h1 className="text-center heading-user-form"> UserFromEmail</h1>


                                        <div className='my-3'>
                                            <label className='detail-label'>Internal Title:</label>
                                            <div>{internalTitle}</div>
                                        </div>
                                        <div className='my-3'>
                                            <label className='detail-label'>Description:</label>
                                            <div>{description}</div>
                                        </div>
                                        <div className='my-3'>
                                            <label className='detail-label'>Short Description:</label>
                                            <div>{shortDescription}</div>
                                        </div>
                                        <form onClick={handleSignUp}>
                                            <div className="mb-3">
                                                <label htmlFor="email" className="form-label detail-label">User Landing From Email</label>
                                                <input
                                                    id="email"
                                                    type="email"
                                                    placeholder="Email"
                                                    className="form-control form-input text-black"
                                                    name="email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                                <input
                                                    id="name"
                                                    type="text"
                                                    placeholder="Name"
                                                    className="form-control form-input text-black fname"
                                                    name="name"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                />
                                                <input
                                                    id="tenant_id"
                                                    type="hidden"
                                                    placeholder="tenant_id"
                                                    className="form-control form-input text-black"
                                                    name="tenant_id"
                                                    value={tenant_id}
                                                    onChange={handleOpeningIdChange}
                                                    required
                                                    autoFocus
                                                />
                                                <input
                                                    id="opening_id"
                                                    type="hidden"
                                                    placeholder="opening_id"
                                                    className="form-control form-input text-black"
                                                    name="name"
                                                    value={opening_id}
                                                    onChange={handleTenantIdChange}
                                                    required
                                                    autoFocus
                                                />

                                            </div>

                                            <div className="mb-3">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary mb-3" title="Continue">Continue</button>
                                            </div>
                                            {/* <div className="text-center">
                                            <h6 className=""> Already have an account?  <Link to="/sign-in" title="Sign up here!">Login!</Link></h6>
                                        </div> */}
                                        </form>

                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 right_section">
                                    <div className="form-right-img">
                                        <img src="https://storage.googleapis.com/yoodli-public/onboarding-assets/presentation1.png" alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
