import { BrowserRouter } from "react-router-dom";
import { PageRoutes } from "./pageRoutes";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from "./component/context/AuthenticationContext";
// import dotenv from "dotenv";
// dotenv.config();
const App = () => {
  return (
    <div className="App">
      <AuthProvider>
        <BrowserRouter>
          <PageRoutes />
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
