import React, { useRef, useEffect, useState } from 'react';
import "./testing.css";

const VideoPlayer: React.FC = () => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [duration, setDuration] = useState<number>(0);
    const [progressPercentage, setProgressPercentage] = useState<number>(0);
    const [videoStarted, setVideoStarted] = useState<boolean>(false);

    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            const updateProgress = () => {
                if (video.currentTime && video.duration) {
                    setCurrentTime(video.currentTime);
                    setDuration(video.duration);
                    setProgressPercentage((video.currentTime / video.duration) * 100);
                }
            };

            const interval = setInterval(updateProgress, 500); // Update every 500ms

            return () => clearInterval(interval);
        }
    }, []);

    const formatTime = (timeInSeconds: number): string => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const handleTimeUpdate = () => {
        if (videoRef.current) {
            setCurrentTime(videoRef.current.currentTime);
            setProgressPercentage((videoRef.current.currentTime / videoRef.current.duration) * 100);
        }
    };

    const handleSeek = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (videoRef.current) {
            const seekPosition = (event.nativeEvent.offsetX / event.currentTarget.offsetWidth) * duration;
            videoRef.current.currentTime = seekPosition;
        }
    };

    const handleStartVideo = () => {
        if (videoRef.current && !videoStarted) {
            videoRef.current.play();
            setVideoStarted(true);
        }
    };

    return (
        <div>
            <div className="video-container" onClick={handleStartVideo}>
                <video
                    ref={videoRef}
                    autoPlay={false}
                    onTimeUpdate={handleTimeUpdate}
                    className="video"
                >
                    <source
                        src="https://d34hmiuaex7c0.cloudfront.net/5159/uploads/page_featured_video_1721038765641.mp4"
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>
                {!videoStarted && (
                    <button className="start-button">Start Video</button>
                )}
            </div>
            <div className="progress-bar" onClick={handleSeek}>
                <div
                    className="progress-filled"
                    style={{ width: `${progressPercentage}%` }}
                />
            </div>
            <div className="time-display">
                <span>{formatTime(currentTime)}</span> / <span>{formatTime(duration)}</span>
            </div>
        </div>
    );
};

export default VideoPlayer;
