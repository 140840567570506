import { Link } from "react-router-dom";

export const Home = () => {
    return (
        <>
            <section>
                <div className="container-fluid">
                    <div className="container">
                        <div
                            className="row justify-content-center align-items-center"
                            style={{ height: "100vh" }}
                        >
                            <div className="col-sm-12 col-md-6 col-lg-6 m-auto">
                                <div className="col-md-12 my-2">
                                    <div className="home-heading">
                                        {/* <h1>Work presentation</h1> */}
                                        <h1>Navigating Tomorrow's Leaders </h1>
                                        {/* <p>Improve your communication skills with private, realtime, and judgement free speech coaching on your online meetings - powered by AI</p> */}
                                        <p>Join us as we seek to discover and engage with the future leaders of our
                                            organization. This interview session is tailored for college recruits,
                                            offering an opportunity to showcase your skills, aspirations, and unique qualities.
                                            Be a part of our journey in identifying and nurturing the talent that will shape
                                            the future of our company. We look forward to connecting with ambitious individuals
                                            who are ready to embark on a rewarding career path.
                                        </p>
                                    </div>
                                    <div className="start-btn">
                                        <Link to="/allopenings" type="button">Start</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="col-md-12 my-2">
                                    <video
                                        controls
                                        width="100%"
                                        autoPlay // Corrected here
                                        loop    // For looping the video
                                        muted // You might need this to ensure autoPlay works in most browsers
                                        height="auto"
                                        style={{ borderRadius: "15px" }}
                                        // src="https://storage.googleapis.com/yoodli-public/landing-page-assets/lp-hero-video.webm"
                                        src="https://strapi-cms.nyc3.digitaloceanspaces.com/content/b5ba790ae168852a8c2c3084f0cfbead/Vidcruiter-ProcessV2.mp4?updated_at=2023-10-30T19:17:15.652Z"
                                    >
                                        Sorry, your browser doesn't support embedded videos.
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
