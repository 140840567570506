import React, { useRef, useState } from 'react';

const CameraComponent: React.FC = () => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isCameraEnabled, setIsCameraEnabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const enableCamera = async () => {
        setErrorMessage(''); // Clear previous errors
        try {
            if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
                throw new Error('getUserMedia is not supported in this browser.');
            }

            const stream = await navigator.mediaDevices.getUserMedia({ video: true });

            if (videoRef.current) {
                videoRef.current.srcObject = stream;
            }
            setIsCameraEnabled(true);
        } catch (error: unknown) {
            console.error('Error accessing camera: ', error);

            // Ensure that error is an instance of Error
            if (error instanceof Error) {
                if (error.name === 'NotAllowedError') {
                    setErrorMessage('Camera access denied. Please allow camera access in your browser settings.');
                } else if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
                    setErrorMessage('No camera found. Please connect a camera and try again.');
                } else {
                    setErrorMessage(`Error accessing camera: ${error.message}`);
                }
            } else {
                setErrorMessage('An unknown error occurred.');
            }
        }
    };

    return (
        <div>
            <button className='center-button' onClick={enableCamera}>Enable Camera</button>
            {errorMessage && (
                <div>
                    <p className="error">{errorMessage}</p>
                    {errorMessage.includes('allow camera access') && (
                        <p>
                            Please follow these steps to allow camera access:
                            <ol>
                                <li>Click on the lock icon next to the URL in the address bar.</li>
                                <li>Go to "Site settings" or "Permissions."</li>
                                <li>Find "Camera" and set it to "Allow."</li>
                                <li>Refresh the page and try again.</li>
                            </ol>
                        </p>
                    )}
                </div>
            )}
            {isCameraEnabled && (
                <div>
                    {/* <video ref={videoRef} autoPlay style={{ width: '100%' }} /> */}
                </div>
            )}
        </div>
    );
};

export default CameraComponent;
