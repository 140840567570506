import React, { useState, useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';
import videoImage from "../assets/images/defult-Video.png"
import { useNavigate } from "react-router-dom";
import { SideBar } from './SideBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { faFlag } from '@fortawesome/free-solid-svg-icons';

interface QuizQuestion {
  question: string;
  options: string[];
  correctAnswer: string;
  videoURL: string;
  timer: number;
}

const quizQuestions: QuizQuestion[] = [
  {
    question: "What is the largest ocean on Earth?",
    options: ["Atlantic Ocean", "Indian Ocean", "Southern Ocean", "Pacific Ocean"],
    correctAnswer: "Pacific Ocean",
    videoURL: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4",
    timer: 300,
  },
  {
    question: "Which planet is known as the 'Red Planet'?",
    options: ["Mars", "Venus", "Earth", "Jupiter"],
    correctAnswer: "Mars",
    videoURL: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
    timer: 300,
  },
];

export const TestQuiz: React.FC = () => {
  const [correctAnswersCount, setCorrectAnswersCount] = useState<number>(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<{ [key: number]: string | null }>({});
  const [showScore, setShowScore] = useState(false);
  const [score, setScore] = useState(0);
  const [questionTimer, setQuestionTimer] = useState<NodeJS.Timer | undefined>(undefined);
  const [remainingTime, setRemainingTime] = useState<number | null>(null);
  const [timeUp, setTimeUp] = useState<boolean>(false);
  const [quizStarted, setQuizStarted] = useState<boolean>(false); // New state variable
  const [countdownTimer, setCountdownTimer] = useState<number | null>(null);
  const [loginFailed, setLoginFailed] = useState(false);
  const [countdownTimer_live, setCountdownTimer_live] = useState<number | null>(null);
  const [currentSelectedOption, setCurrentSelectedOption] = useState<string | null>(null);
  const navigate = useNavigate();

  const remainingTimePerQuestion = quizQuestions.map(
    (question, index) => (selectedOptions[index] === undefined ? question.timer : 0)
  );
  const totalRemainingTime = remainingTimePerQuestion.reduce((acc, time) => acc + time, 0);
  const countdownDuration_live = totalRemainingTime * 100; // Convert total remaining time to milliseconds

  const startCountdownLive = () => {
    let timer_live = countdownDuration_live / 100; // Convert to seconds

    const countdownTimer_liveId = setInterval(() => {
      timer_live -= 1;
      setCountdownTimer_live(timer_live);

      if (timer_live <= 0) {
        setTimeout(() => {
          setShowScore(true);
          handleAutoSubmit();
        }, 3000); //
        clearInterval(countdownTimer_liveId);
      }
    }, 1000);

    return () => clearInterval(countdownTimer_liveId);
  };

  useEffect(() => {
    // Start the quiz timer after 30 seconds
    setQuizStarted(true);
    console.log('Quiz start will be after 30 sec!');
    const countdownDuration = 30000; // Countdown duration in milliseconds
    let timer = countdownDuration / 1000; // Convert to seconds

    const quizTimerId = setInterval(() => {
      timer -= 1;
      setCountdownTimer(timer);
      console.log(`Quiz start will be after ${timer} sec!`);

      if (timer <= 0) {
        // Your quiz timer logic goes here
        startCountdownLive();
        setQuizStarted(false);
        console.log('Quiz started!');
        if (currentQuestionIndex < quizQuestions.length - 1) {
          startQuestionTimer();
        }
        clearInterval(quizTimerId);
      }
    }, 1000);
    return () => clearInterval(quizTimerId);
  }, []);

  const startQuestionTimer = () => {
    const currentQuestion = quizQuestions[currentQuestionIndex];
    if (currentQuestionIndex < quizQuestions.length) {
      setRemainingTime(currentQuestion.timer);
      // Clear existing question timer
      if (questionTimer) {
        clearInterval(questionTimer);
      }

      const questionTimerId = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime !== null) {
            const newTime = prevTime - 1;

            if (newTime <= 0) {
              // Time is up for the current question
              clearInterval(questionTimerId);
              if (currentQuestionIndex === quizQuestions.length - 1) {
                // Last question, set remaining time to null
                setRemainingTime(null);
                console.log('Remaining time set to null on the last question');
              } else {
                // Move to the next question
                handleNextClick();
              }
            }
            return newTime;
          }
          return null;

        });
      }, 1000);
      // Save the question timer ID to clear it later
      setQuestionTimer(questionTimerId);
    }
  };

  const handleNextClick = () => {
    setTimeUp(false);
    setCurrentQuestionIndex((prevIndex: number) => Math.min(prevIndex + 1, quizQuestions.length - 1));

    // Store the selected option for the current question
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [currentQuestionIndex]: selectedOption,
    }));

    setSelectedOption(selectedOptions[currentQuestionIndex + 1] || null);
    setRemainingTime(null);

    if (currentQuestionIndex < quizQuestions.length - 1) {
      if (!isLastQuestion) {
        startQuestionTimer();
      } else {
        setShowScore(true);
        setTimeUp(false);
        setRemainingTime(null);
        clearInterval(questionTimer);
        startQuestionTimer();
      }
    }
  };

  const handlePrevClick = () => {
    setCurrentQuestionIndex((prevIndex: number) => Math.max(prevIndex - 1, 0));
    setTimeUp(false);
    const prevSelectedOption = selectedOptions[currentQuestionIndex - 1] || null;
    setSelectedOption(prevSelectedOption);
    setRemainingTime(null);
    if (currentQuestionIndex > 0) {
      if (!isLastQuestion) {
        startQuestionTimer();
      }
    }
  };


  const handleOptionChange = (option: string) => {
    console.log("Selected option:", option);
    // Update the selected option for the current question
    setSelectedOption(option);
    // Update the selectedOptions state
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [currentQuestionIndex]: option,
    }));
  };


  const handleSubmitClick = () => {
    const currentQuestion = quizQuestions[currentQuestionIndex];
    const isCorrect = selectedOption === currentQuestion.correctAnswer;
    console.log(`Question ${currentQuestionIndex + 1}: Selected = ${selectedOption}, Correct = ${currentQuestion.correctAnswer}, IsCorrect = ${isCorrect}`);

    if (isCorrect) {
      setScore((prevScore) => prevScore + 1);
      setCorrectAnswersCount((prevCount) => prevCount + 1);
    }

    if (currentQuestionIndex === quizQuestions.length - 1) {
      // If it's the last question, show the score
      setShowScore(true);
      setTimeUp(false); // Set time up to false
      setRemainingTime(null); // Set remaining time to null
      clearInterval(questionTimer);
    } else {
      handleNextClick();
    }
    // Move to the next question
    handleNextClick();
  };

  const currentQuestion = quizQuestions[currentQuestionIndex];
  const isLastQuestion = currentQuestionIndex === quizQuestions.length - 1;
  const isQuizCompleted = currentQuestionIndex === quizQuestions.length;

  const closePopup = () => {
    setLoginFailed(false);
    setShowScore(false);
    navigate("/tickets");
  };

  const handleAutoSubmit = () => {
    console.log('Auto-submitting...');
    handleSubmitClick();
  };

  function formatTime(seconds: any) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  }

  const getTotalCorrectAnswers = () => {
    const totalCorrectAnswers = Object.values(selectedOptions).filter((selectedOption, index) => {
      const isCorrect = selectedOption === quizQuestions[index].correctAnswer;
      console.log(`Question ${index + 1}: Selected = ${selectedOption}, Correct = ${quizQuestions[index].correctAnswer}, IsCorrect = ${isCorrect}`);
      return isCorrect;
    }).length;

    console.log(`Total Correct Answers: ${totalCorrectAnswers}`);
    return totalCorrectAnswers;
  };
  
  return (
    <div className='sidebar_container'>
      <SideBar />
      <div className="container pt-4">
        <section className='d-flex content-justify-center flex-column m-auto'>
        
          <div className='d-flex justify-content-between w-100 pb-3'>
            <h3>C Test</h3>
            <Button variant="primary" data-bs-toggle="modal" data-bs-target="#exampleModalTwo">
              <span> <FontAwesomeIcon icon={faFlag} /></span>    Report Question
            </Button>

          </div>
          <Card>
            <div className='container'>
              <div className="text-center py-3 mb-3">
                {remainingTime !== null ? (<p>Time remaining : {formatTime(countdownTimer_live)} seconds</p>)
                  : (<p>The test will start in : {countdownTimer} seconds</p>)
                }
              </div>
              <div className='row'>

                <div className='col-md-6 col-sm-6' style={{ display: "none" }}>
                  <Card.Body>
                    <Card.Title>{`Q${currentQuestionIndex + 1}: ${currentQuestion.question}`}</Card.Title>
                    <Card.Text>
                      <ul style={{ listStyle: 'none', padding: 0 }}>
                        {currentQuestion.options.map((option, i) => (
                          <li key={i} style={{
                            marginBottom: '10px'
                          }}>
                            <input
                              type="radio"
                              id={`option${i}`}
                              name="options"
                              checked={selectedOption === option}
                              onChange={() => handleOptionChange(option)}
                            />
                            <label htmlFor={`option${i}`} className="ml-2 px-2">{`${i + 1}. ${option}`}</label>
                          </li>
                        ))}
                      </ul>
                    </Card.Text>
                  </Card.Body>
                </div>
                {remainingTime !== null ? (
                  <div className="col-md-6 col-sm-6 m-auto">
                    <div className='camera-width-con'>
                      {quizQuestions.map((question, index) => (
                        <video key={index} autoPlay muted width="100%" style={{ display: index === currentQuestionIndex ? 'block' : 'none' }}>
                          <source src={question.videoURL} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="col-md-6 col-sm-6 m-auto">
                    <div className='camera-width-con'>
                      <img src={videoImage} alt="backgrounImage" />
                    </div>
                  </div>

                )}
              </div>

            </div>
            <Card.Footer>
              <div className="col-md-12 col-sm-12 mt-2 py-3 text-end">
                {remainingTime !== null && (<>
                  <div className='d-flex gap-3 justify-content-end'>
                    <Button variant="primary" data-bs-toggle="modal" data-bs-target="#exampleModalOne">
                      <span>
                        <FontAwesomeIcon icon={faInfo} />
                      </span>  Info
                    </Button>

                    <Button variant="primary" onClick={handleSubmitClick}>
                      Submit
                    </Button>
                  </div>
                </>)}
              </div>

            </Card.Footer>
          </Card>
        </section>
        <div className="row">
          <div className="col-md-12 col-sm-12 text-center">
            {showScore && (
              <div className="overlay">
                <div className="popup" style={{width:"450px"}}>
                  <div className="text-success py-3">
                   <p className='text-left'> Congratulations! Your quiz has been successfully submitted. 
                    Thank you for participating and testing your knowledge.
                     Results will be available shortly. Good luck! </p>
                  </div>
                  <div className="text-danger py-3">
                    {/* <h4>Your Score: {getTotalCorrectAnswers()}/ {quizQuestions.length}</h4> */}
                  </div>
                  <button className="btn btn-primary py-2" onClick={closePopup}>Close</button>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* modal for report */}
        <div className="all_model">
          <div
            className="modal fade"
            id="exampleModalTwo"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-labelledby="exampleModalTowLabel"
            aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalTowLabel">Report Question</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className='d-flex gap-2 py-4 align-items-baseline'>
                    <div className='issue-div'>
                      <p className='text-let'>Select issue </p>
                    </div>
                    <div className='select-box'>
                      <select className="form-select" aria-label="Default select example">
                        <option selected>Please select a value</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="validationTextarea" className="form-label">Note</label>
                    <textarea className="form-control" id="validationTextarea" placeholder="Add Your Feedback here" required>
                    </textarea>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary" data-bs-dismiss="modal"> Submit Feedback </button>
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* modal for info */}
        <div className="all_model">
          <div
            className="modal fade"
            id="exampleModalOne"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-labelledby="exampleModalOneLabel"
            aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalOneLabel">C Test</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <p className="text-left">In the upcoming test exam, participants will have the opportunity
                    to showcase their knowledge and skills in a controlled environment. The exam will cover a
                    range of topics, testing both theoretical understanding and practical application. </p>
                  <ul className='ul-list'>
                    <li className='li-list'>Introduction to the exam format and structure. </li>
                    <li className='li-list'>Review of key topics and concepts to be covered. </li>
                    <li className='li-list'>Practice sessions for problem-solving and critical thinking. </li>
                    <li className='li-list'>Guidance on effective time management during the exam. </li>
                    <li className='li-list'>Tips for staying focused and maintaining composure throughout the test. </li>
                  </ul>
                  <p className='text-left py-2'>Best Luck!</p>
                </div>

                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

//export default QuizComponent;
