import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfigCheck } from './ConfigCheck';
export const CheckSystemConfig: React.FC = () => {
    const [hasWebcam, setHasWebcam] = useState<boolean | null>(null);
    const [hasMic, setHasMic] = useState<boolean | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate(); 

    useEffect(() => {
        const timer = setTimeout(() => {
            if (navigator && navigator.mediaDevices) {
                navigator.mediaDevices.enumerateDevices().then(devices => {
                    const webcam = devices.some(device => device.kind === "videoinput");
                    const mic = devices.some(device => device.kind === "audioinput");

                    setHasWebcam(webcam);
                    setHasMic(mic);
                    setLoading(false);

                    if (webcam) {
                        // navigate('/testQuiz');
                        navigate('/tickets');

                    }
                    else{
                        // navigate('/tickets');
                    }
                  
                });
            }
        }, 2000); 

        return () => clearTimeout(timer); 
    }, [navigate]); 

    return (
        <div className='spinner-section'>
            {loading ? (
                <div className="spinner m-auto"></div> 
            ) : (
                 <>  
                 {/* <div className='d-flex m-auto text-center flex-column'>
                    <p className='PX-2'>Webcam: {hasWebcam ? "Available" : "Not Available"}</p>
                    <p className='px-2'>Microphone: {hasMic ? "Available" : "Not Available"}</p>
                 </div> */}
                 <ConfigCheck/>
                </>
            )}
        </div>
    );
}
