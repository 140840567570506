import axios from "axios";
import { quizListURL } from "./endpoints";

interface saveOpeningPorps {
  ID: string;
  ServiceEnquiry: string;
  Title: string;
  FName: string;
  LName: string;
  Mobile: string;
  DestMob: string;
}
export async function getQuizListAPI(): Promise<any> {
  try {
    const response = await axios.get(quizListURL);

    return response.data;
  } catch (error) {
    console.error("Error fetching quiz list:", error);
    throw error;
  }
}
export async function saveQuiz(
  saveOpeningPayload: saveOpeningPorps
) {
  const signedTokenJSON = localStorage.getItem("quizToken");
  const signedToken = JSON.parse(String(signedTokenJSON));

  const headers = {
    Authorization: `Bearer ${signedToken.signedToken}`,
  };

  const payload = { ...saveOpeningPayload };
  try {
    const response = await axios.post(quizListURL, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error in checking Save Quiz", error);
    throw error;
  }
}

export async function getOpeningAPI(): Promise<any> {
  try {
    const response = await axios.get(quizListURL);
    return response.data;
  } catch (error) {
    console.error("Error fetching opening list:", error);
    throw error;
  }
}

export async function saveOpening(
  saveOpeningPayload: saveOpeningPorps
) {
  const signedTokenJSON = localStorage.getItem("openingToken");
  const signedToken = JSON.parse(String(signedTokenJSON));

  const headers = {
    Authorization: `Bearer ${signedToken.signedToken}`,
  };

  const payload = { ...saveOpeningPayload };
  try {
    const response = await axios.post(quizListURL, payload, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error in checking Save Opening", error);
    throw error;
  }
}
